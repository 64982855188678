/* add base styles for the app here */
.u-overflow-y-auto {
  overflow-y: auto;
}
.u-sizeFill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 0 0% !important;
          flex: 1 0 0% !important;
  overflow: inherit!important;
}
br.u-sizeFull {
  content: '';
}
.Banner-main .BCOverlap {
  padding-top: 190px;
}
blockquote {
  display: block;
  color: #18274a;
  text-align: center;
  border: none;
  padding: 0.9375rem 1.25rem;
}
@media only screen and (min-width: 48em), print {
  blockquote {
    padding: 1.25rem;
  }
}
blockquote p {
  font-size: 1.25rem !important;
  margin: 0 auto;
  font-size: 20px;
  line-height: 28px;
}
@media only screen and (min-width: 48em), print {
  blockquote p {
    font-size: 24px;
    line-height: 35px;
  }
}
blockquote p:before {
  display: block;
  content: '\e902';
  font-size: 20px;
  line-height: 1;
  font-family: Getinge-icon;
  margin-bottom: 20px;
}
@media only screen and (min-width: 48em), print {
  blockquote p:before {
    font-size: 20px;
  }
}
blockquote p:after {
  display: block;
  content: '\e902';
  font-size: 20px;
  line-height: 1;
  font-family: Getinge-icon;
  margin-top: 20px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
@media only screen and (min-width: 48em), print {
  blockquote p:after {
    font-size: 20px;
  }
}
blockquote:after {
  content: "- " attr(cite) attr(Title);
  display: block;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
}
@media only screen and (min-width: 48em), print {
  blockquote:after {
    font-size: 19px;
    line-height: 30px;
  }
}
.Category {
  margin-bottom: 0.9375rem;
}
@media only screen and (min-width: 48em), print {
  .Category {
    margin-bottom: 0;
  }
}
.TeaserList .Teaser .Category-item:nth-child(n+4) {
  display: none;
}
.TeaserList .Teaser:hover .Category-item:nth-child(n+4),
.TeaserList .Teaser.hovered .Category-item:nth-child(n+4) {
  display: inline-block;
}
.Category-label {
  font-size: 0.875rem;
  margin-right: 0.625rem / 2;
}
.Category-item {
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #ede9e9;
  border-radius: 30px;
  padding: 0.4rem 0.8rem;
  margin-right: calc(0.625rem / 2);
  margin-bottom: calc(0.625rem / 2);
}
.Category-Accredited {
  position: relative;
  line-height: unset;
  padding: 0.2rem 1.2rem 0.4rem;
}
.Category-Accredited:before {
  content: url('/assets/Images/Accredited.svg');
  position: relative;
  display: inline-block;
  top: 3px;
  left: 0;
  width: 20px;
}
.Input,
input[type=text],
input[type=password],
input[type=number],
input[type=search],
input[type=tel],
input[type=email],
input[type=url],
textarea {
  width: 100%;
  display: block;
  font-size: 1rem;
  font-weight: normal;
  line-height: 21px;
  color: #192849;
  border: 1px solid #c0c0c0;
  height: 58px;
  padding-left: 24px;
  color: #18274a;
  border-radius: 2px;
}
.Input:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=email]:focus,
input[type=url]:focus,
textarea:focus {
  border: 1px solid #c0c0c0;
  background: #FAFAFA;
}
.Link--bordered {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 1rem;
  vertical-align: middle;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  white-space: normal;
  -webkit-transition: 0.15s ease-in;
  transition: 0.15s ease-in;
  -webkit-transition-property: border, color, padding, background-color;
  transition-property: border, color, padding, background-color;
  color: #18274a;
  padding: 0 1.5rem;
  border: 2px solid #18274a;
  /* every button type have borders so that they are all in the same height */
  border-radius: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-width: 100px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 40px;
  text-align: center;
}
.Link--bordered:hover {
  color: #fff;
  background-color: #18274a;
  border-color: #18274a;
}
.Link--fullWidth {
  width: 100%;
}
.OfficeList {
  background: #f6f5f5;
  margin-bottom: 2.5rem;
}
.OfficeList-content {
  padding: 1.5625rem 2.5rem 1.5625rem 1.5625rem;
}
.OfficeList-heading {
  border-bottom: 1px solid #ede9e9;
}
.OfficeList-heading i {
  font-size: 14px;
}
.OfficeList-map {
  background: #ececeb;
  height: 100%;
}
.OfficeList-list label {
  color: #18274a;
  font-weight: 500;
  margin-right: 5px;
  min-width: 50px;
}
.Officelist-select {
  width: 100%;
  max-width: 420px;
  margin-bottom: 1.875rem;
}
.ContactInfo-officeName {
  font-weight: 400;
  display: block;
  margin-bottom: 0.9375rem;
}
.Office-accordionPart {
  display: block;
}
.Office-list,
.OfficeList-list {
  padding-left: 0;
  margin-bottom: 0;
}
.Office-list li,
.OfficeList-list li {
  list-style: none;
  margin-bottom: 0.625rem;
}
.Office-list li label,
.OfficeList-list li label {
  display: block;
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
}
.Office-list li a,
.OfficeList-list li a {
  color: #18274a;
}
.SelectButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  padding: 0.625rem;
  -webkit-transition: background ease-in-out 0.4s;
  transition: background ease-in-out 0.4s;
  border: 1px solid #8A7D79;
  width: 100%;
  background: #fff;
  color: #5F5F5F;
  height: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media only screen and (min-width: 48em), print {
  .SelectButton {
    padding: 1.5625rem;
  }
}
.SelectButton .SelectButton-text {
  color: #5F5F5F;
  font-size: 15px;
  line-height: 21px;
  display: block;
  -webkit-transition: color ease-in-out 0.2s;
  transition: color ease-in-out 0.2s;
}
.SelectButton--hasIcon {
  padding: 1.25rem 1.5625rem;
}
@media only screen and (min-width: 48em), print {
  .SelectButton--hasIcon {
    margin-bottom: 0;
  }
}
.SelectButton .SelectButton-icon {
  margin-top: 15px;
}
@media only screen and (min-width: 48em), print {
  .SelectButton .SelectButton-icon {
    margin-top: 25px;
  }
}
.SelectButton .SelectButton-icon:before {
  color: #18274a;
  -webkit-transition: fill ease-in-out 0.2s;
  transition: fill ease-in-out 0.2s;
  font-size: 30px;
}
@media only screen and (min-width: 48em), print {
  .SelectButton .SelectButton-icon:before {
    font-size: 50px;
  }
}
.SelectButton.is-active {
  background: #18274a;
  border-color: #18274a;
}
.SelectButton.is-active .SelectButton-text,
.SelectButton.is-active .SelectButton-heading,
.SelectButton.is-active .SelectButton-icon:before {
  color: #fff;
}
.SelectButton.is-active:hover {
  background: #18274a;
}
.SelectButton.is-active:hover .SelectButton-text,
.SelectButton.is-active:hover .SelectButton-heading {
  color: #fff;
}
.SelectButton.is-active:hover .SelectButton-icon:before {
  color: #fff;
}
.SelectButton:hover {
  background: #FAFAFA;
  text-decoration: none;
}
.SelectButton:hover .SelectButton-text {
  color: #18274a;
}
.SelectButton:hover .SelectButton-icon {
  fill: #18274a;
}
.SelectButton-text {
  color: #fff;
}
.Share {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.Share-link {
  border: 2px solid #ede9e9;
  border-radius: 100%;
  height: 36px;
  width: 36px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}
@media only screen and (min-width: 48em), print {
  .Share-link {
    height: 41px;
    width: 41px;
  }
}
.Share-link:first-child {
  margin-left: 0;
}
.Share-link span {
  color: #18274a;
  font-size: 14px;
}
@media only screen and (min-width: 48em), print {
  .Share-link span {
    font-size: 16px;
  }
}
.Share-link:hover {
  text-decoration: none;
  border-color: #18274a;
}
/* the slidemodal will open a modal by sliding it in from the right side of the page */
.SlideModal-wrapper {
  height: 100dvh;
  width: 100%;
  right: -100%;
  top: 0;
  padding: 1.5625rem;
  position: fixed;
  -webkit-transition: right 0.4s ease-in-out;
  transition: right 0.4s ease-in-out;
  z-index: 12;
  background: #fff;
  color: #595857;
  text-align: initial;
}
@media only screen and (min-width: 57.5em), print {
  .SlideModal-wrapper {
    width: 585px;
    right: -585px;
    padding: 90px 60px 60px;
  }
}
.SlideModal-wrapper.is-open {
  right: 0;
}
.SlideModal-wrapper .Heading,
.SlideModal-wrapper h2,
.SlideModal-wrapper h3,
.SlideModal-wrapper h4 {
  color: #18274a !important;
}
.SlideModal-wrapper .Button {
  -webkit-transition: 0.15s ease-in;
  transition: 0.15s ease-in;
  background: #18274a !important;
  border: 1px solid #18274a !important;
  color: #fff !important;
}
.SlideModal-wrapper .Button:hover {
  border-color: #495a6b !important;
  background-color: #495a6b !important;
}
.SlideModal-close {
  position: absolute;
  top: 50px;
  right: 50px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.SlideModal-content {
  overflow-y: auto;
}
.StockTicker .StockTicker-price {
  font-weight: bold;
  margin-right: 10px;
}
.StockTicker .StockTicker-price.is-negative:before {
  content: '▼';
  padding-right: 5px;
}
.StockTicker .StockTicker-price.is-positive:before {
  content: '▲';
  padding-right: 5px;
}
.StockTicker .StockTicker-change.is-negative {
  color: #e56b78;
}
.StockTicker .StockTicker-change.is-positive {
  color: #94b654;
}
.Subscribe .Subscribe-label {
  font-size: 15px;
  display: block;
  color: #595857;
}
.Subscribe .Checkbox,
.Subscribe .Input {
  display: block;
  margin-bottom: 0.625rem;
}
.Subscribe .Input {
  margin: 1.5625rem 0;
}
.Subscribe .Subscribe-Preference .Checkbox {
  display: block;
  margin-bottom: 0.4rem;
}
.Subscribe-fail {
  padding: 1rem;
  background: #f9f8f8;
}
.Subscribe .Checkbox-label p {
  margin: 0;
}
.Subscribe .Subscribe--Checkbox-link a {
  color: #e56b78;
}
/*@import "../../../../core/Styles/components/Atoms/Text";*/
.Text--small,
.ColorTeaser-text {
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 18px;
}
@media only screen and (min-width: 48em), print {
  .Text--small,
  .ColorTeaser-text {
    font-size: 1rem;
    line-height: 25px;
  }
}
.Text--medium,
.Document-intro {
  font-weight: 300;
  font-size: 1rem;
  line-height: 22px;
}
@media only screen and (min-width: 48em), print {
  .Text--medium,
  .Document-intro {
    font-size: 1.1875rem;
    line-height: 28px;
  }
}
.Text-caps {
  font-weight: 300;
  font-size: 11px;
  line-height: 18px;
  text-transform: uppercase;
}
@media only screen and (min-width: 48em), print {
  .Text-caps {
    font-size: 12px;
    line-height: 20px;
  }
}
p a,
li a {
  color: #31b7bc;
}
p a:hover,
li a:hover {
  color: #31b7bc;
  text-decoration: underline;
}
.Select-style {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  line-height: 21px;
  color: #192849;
  border: 1px solid #c0c0c0;
  height: 58px;
  color: #18274a;
  border-radius: 2px;
}
.Select-style:after {
  top: unset;
  bottom: 18px;
  right: 18px;
  background-color: transparent;
  background-image: none;
  content: "\e906";
  font-family: 'Getinge-icon';
  font-size: 7px;
  width: auto;
}
.Select-style .Select-input {
  padding-left: 20px;
  height: 100%;
  padding: 0 0 0 20px;
}
.Status-closeLink {
  right: 1.5625rem;
  padding: 0.3em 0;
}
.Column-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 100%;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.Column-social a {
  margin: 0 10px;
}
.Column-social a:first-child {
  margin-left: 0;
}
.Column-social a span {
  font-size: 25px;
}
.Column-social a span.icon-youtube {
  position: relative;
  top: 3px;
}
.Column-social a:hover {
  text-decoration: none;
}
.Banner--videoWrapper .Banner-content {
  position: unset;
}
.Column-videoLink img {
  aspect-ratio: unset;
}
.Banner-main--large .Banner {
  aspect-ratio: unset !important;
}
.Banner--videoWrapper.Banner--imageRight .Banner-content,
.Banner--videoWrapper Banner--imageLeft .Banner-content {
  position: relative;
}
.Modal-dialog .CallToAction .Link {
  color: #fff;
}
.Column-image + .Column-text {
  padding-top: 0;
}
.Column-image + .Column-text .Heading {
  padding-top: 1.5625rem;
}
#accordionReferencePanel p {
  display: inline;
}
@media only screen and (min-width: 57.5em), print {
  .Banner.Banner--bgCoverContent.Banner--imageRight .Banner-content .Banner-textWrapper {
    padding-left: 1.5625rem;
  }
}
.Theme--themeMidnight2,
.Theme--themeMidnight,
.ColorTeaser--themeMidnight2 {
  background: #495a6b;
  color: #fff;
}
.Theme--themeMidnight2 .Heading,
.Theme--themeMidnight .Heading,
.Theme--themeMidnight2 h1,
.Theme--themeMidnight h1,
.Theme--themeMidnight2 h2,
.Theme--themeMidnight h2,
.Theme--themeMidnight2 h3,
.Theme--themeMidnight h3,
.Theme--themeMidnight2 h4,
.Theme--themeMidnight h4,
.Theme--themeMidnight2 h5,
.Theme--themeMidnight h5,
.Theme--themeMidnight2 h6,
.Theme--themeMidnight h6 {
  color: #fff;
}
.Theme--themeMidnight2 blockquote,
.Theme--themeMidnight blockquote {
  color: #fff;
}
.Theme--themeMidnight1 {
  background: #495a6b;
  color: #fff;
}
.Theme--themeMidnight1 .Heading,
.Theme--themeMidnight1 h1,
.Theme--themeMidnight1 h2,
.Theme--themeMidnight1 h3,
.Theme--themeMidnight1 h4,
.Theme--themeMidnight1 h5,
.Theme--themeMidnight1 h6 {
  color: #94b654;
}
.Theme--themeMidnight1 blockquote {
  color: #fff;
}
.Theme--themeSnow {
  background: #e9e4e3;
  color: #595857;
}
.Theme--themeSnow .Heading,
.Theme--themeSnow h1,
.Theme--themeSnow h2,
.Theme--themeSnow h3,
.Theme--themeSnow h4,
.Theme--themeSnow h5,
.Theme--themeSnow h6 {
  color: #18274a;
}
.Theme--themeBlue,
.Theme--themeBlue1,
.ColorTeaser--themeBlue1 {
  background: #18274a;
  color: #fff;
}
.Theme--themeBlue .Heading,
.Theme--themeBlue1 .Heading {
  color: #fff;
}
.Theme--themeBlue .ArrowList-item a,
.Theme--themeBlue1 .ArrowList-item a {
  color: #dad9d7;
}
.Theme--themeBlue .ArrowList-item a.is-active,
.Theme--themeBlue1 .ArrowList-item a.is-active {
  color: #fff;
  font-weight: bold;
}
.Theme--themeBlue blockquote,
.Theme--themeBlue1 blockquote {
  color: #fff;
}
.Theme--themeBlue2 {
  background: #18274a;
  color: #fff;
}
.Theme--themeBlue2 .Heading,
.Theme--themeBlue2 h1,
.Theme--themeBlue2 h2,
.Theme--themeBlue2 h3,
.Theme--themeBlue2 h4,
.Theme--themeBlue2 h5,
.Theme--themeBlue2 h6 {
  color: #f39200;
}
.Theme--themeBlue2 blockquote {
  color: #fff;
}
.Theme--themeClay1 {
  background: #bfb1ac;
  color: #595857;
}
.Theme--themeClay1 .Heading {
  color: #18274a;
}
.Theme--themeClay1 .u-linkTextReference {
  color: #18274a;
}
.Theme--themeClay1 .u-linkTextReference:hover {
  color: #18274a;
}
.Theme--themeClay2 {
  background: #bfb1ac;
  color: #fff;
}
.Theme--themeClay2 .Heading {
  color: #fff;
}
.Theme--themeClay2 .u-linkTextReference {
  color: #18274a;
}
.Theme--themeClay2 .u-linkTextReference:hover {
  color: #18274a;
}
.Theme--themeBerry {
  background: #e56b78;
  color: #fff;
}
.Theme--themeBerry .Heading,
.Theme--themeBerry h1,
.Theme--themeBerry h2,
.Theme--themeBerry h3,
.Theme--themeBerry h4,
.Theme--themeBerry h5,
.Theme--themeBerry h6 {
  color: #fff;
}
.Theme--themeCliff,
.ColorTeaser--themeCliff {
  background: #8b7e79;
  color: #fff;
}
.Theme--themeCliff .Heading,
.Theme--themeCliff h1,
.Theme--themeCliff h2,
.Theme--themeCliff h3,
.Theme--themeCliff h4,
.Theme--themeCliff h5,
.Theme--themeCliff h6 {
  color: #fff;
}
.Theme--themeNuclear {
  background: #31b7bc;
  color: #fff;
}
.Theme--themeNuclear .Heading,
.Theme--themeNuclear h1,
.Theme--themeNuclear h2,
.Theme--themeNuclear h3,
.Theme--themeNuclear h4,
.Theme--themeNuclear h5,
.Theme--themeNuclear h6 {
  color: #fff;
}
.Theme--themeNuclear .u-linkTextReference {
  color: #18274a;
}
.Theme--themeNuclear .u-linkTextReference:hover {
  color: #18274a;
}
.Theme--themeSun {
  background: #f39200;
  color: #fff;
}
.Theme--themeSun .Heading,
.Theme--themeSun h1,
.Theme--themeSun h2,
.Theme--themeSun h3,
.Theme--themeSun h4,
.Theme--themeSun h5,
.Theme--themeSun h6 {
  color: #fff;
}
div[class*="Blue"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow),
div[class*="Midnight"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow),
div[class*="themeClay2"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow) {
  background-color: #fff;
  border-color: #fff;
  color: #18274a;
}
div[class*="Blue"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow):hover,
div[class*="Midnight"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow):hover,
div[class*="themeClay2"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow):hover {
  background-color: #e9e4e3;
  border-color: #e9e4e3;
  color: #18274a;
}
div[class*="Blue"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow).Button--bordered,
div[class*="Midnight"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow).Button--bordered,
div[class*="themeClay2"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow).Button--bordered {
  background-color: transparent;
  color: #fff;
}
div[class*="Blue"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow).Button--bordered:hover,
div[class*="Midnight"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow).Button--bordered:hover,
div[class*="themeClay2"] .Button:not(.Button--blue):not(.Button--white):not(.Button--sun):not(.Button--ocean):not(.Button--berry):not(.Button--midnight):not(.Button--snow).Button--bordered:hover {
  background-color: #fff;
  color: #18274a;
}
div[class*="Blue"] .Link,
div[class*="Midnight"] .Link,
div[class*="themeClay2"] .Link {
  color: #fff;
}
div[class*="Blue"] .Link:hover,
div[class*="Midnight"] .Link:hover,
div[class*="themeClay2"] .Link:hover {
  color: #e9e4e3;
}
div[class*="Blue"] .u-linkTextReference:hover,
div[class*="Midnight"] .u-linkTextReference:hover,
div[class*="themeClay2"] .u-linkTextReference:hover {
  color: #e9e4e3;
}
.Theme--themeMidnight2 p a,
.Theme--themeMidnight p a,
.Theme--themeMidnight1 p a,
.Theme--themeBlue p a,
.Theme--themeBlue1 p a,
.Theme--themeBlue2 p a {
  color: #31b7bc;
}
.Theme--themeMidnight2 p a:hover,
.Theme--themeMidnight p a:hover,
.Theme--themeMidnight1 p a:hover,
.Theme--themeBlue p a:hover,
.Theme--themeBlue1 p a:hover,
.Theme--themeBlue2 p a:hover {
  text-decoration: underline;
}
.Theme--themeMidnight2 .Button:hover,
.Theme--themeMidnight .Button:hover,
.Theme--themeMidnight1 .Button:hover,
.Theme--themeBlue .Button:hover,
.Theme--themeBlue1 .Button:hover,
.Theme--themeBlue2 .Button:hover {
  text-decoration: none;
}
.ArrowList {
  list-style: none;
  padding-left: 0;
}
@media only screen and (min-width: 48em), print {
  .ArrowList {
    max-width: 320px;
    padding-left: inherit;
  }
}
.ArrowList-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 5px 5px 5px 0;
}
.ArrowList-item:first-child {
  border-top: 1px solid #e0e0e0;
}
.ArrowList-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.ArrowList-link:after {
  -webkit-transition: 0.15s ease-in;
  transition: 0.15s ease-in;
  display: block;
  content: "\e908";
  font-family: Getinge-icon;
  font-size: 13px;
  position: relative;
  top: 2px;
  left: 0;
}
.ArrowList-link:hover {
  text-decoration: none !important;
}
.ArrowList-link:hover:after {
  left: 4px;
}
.ArrowList-link.is-active {
  font-weight: bold;
}
.Article {
  padding-top: 2.5rem;
}
@media only screen and (min-width: 48em), print {
  .Article {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
  .Article .Banner,
  .Article .Section {
    margin-left: -1.5625rem;
    margin-right: -1.5625rem;
  }
}
@media only screen and (min-width: 57.5em), print {
  .Article {
    padding-top: 0;
    padding-left: 0px;
    padding-right: 0px;
  }
  .Article .Banner,
  .Article .Section {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (min-width: 57.5em), print {
  .Article .SplitContent .SplitContent-left {
    width: 33.33333333% !important;
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
  }
}
@media only screen and (min-width: 57.5em), print {
  .Article .SplitContent .SplitContent-right {
    width: 66.66666667% !important;
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
  }
}
.Article .Column--wideSingleColumn .Wistia-video,
.Article .Column--wideSingleColumn .Column-video,
.Article .Column--wideSingleColumn .Column-image {
  max-width: 48rem;
}
.Article-header {
  padding: 0;
}
@media only screen and (min-width: 48em), print {
  .Article-header {
    padding: 0 0 2.5rem;
  }
}
@media only screen and (min-width: 48em), print {
  .Article-heading {
    max-width: 80%;
  }
}
@media only screen and (min-width: 57.5em), print {
  .Article-heading {
    max-width: 66%;
    margin-bottom: 35px;
  }
}
.Article-associated {
  padding: 1rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.Article-associated .Button {
  margin-right: 0.625rem;
  min-height: 36px;
}
@media only screen and (min-width: 48em), print {
  .Article-associated .Button {
    top: -1px;
    min-height: 40px;
  }
}
.Article-associated .Article-associatedSection {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media only screen and (min-width: 48em), print {
  .Article-associated .Article-associatedSection {
    width: auto;
    display: block;
  }
}
.Article-stickyNavList {
  list-style: none;
  padding-left: 2px;
}
@media only screen and (min-width: 64em) {
  .Article-stickyNavList {
    padding-left: 0.9375rem;
  }
}
@media only screen and (min-width: 80em) {
  .Article-stickyNavList {
    padding-left: 0.9375rem;
  }
}
.Article-stickyNavItem {
  /* display: flex;*/
  display: block;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 200px;
  position: relative;
}
.Article-stickyNavItem a {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 15px;
  min-height: 20px;
}
.Article-stickyNavItem a span {
  opacity: 0;
  background: #fff;
  position: absolute;
  left: 1.25rem;
  padding: 0.625rem / 2;
}
.Article-stickyNavItem a:before {
  margin-right: 6px;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #e56b78;
  display: inline-block;
  aspect-ratio: 1;
}
.Article-stickyNavItem a:hover {
  text-decoration: none;
}
.Article-stickyNavItem a:hover:before {
  background: #bfb1ac;
}
.Article-stickyNavItem a:hover span {
  opacity: 1;
}
.Article-stickyNavItem.is-active a:before {
  background: #000;
}
.Article-stickyNavGrid {
  max-width: 0;
  width: 1%;
}
.Article-grid {
  width: 99%;
}
.Article-stickyNavWrapper {
  top: 0px;
  position: relative;
  z-index: 10;
}
.Article-stickyNavWrapper .Sticky {
  position: sticky;
  width: 100%;
  top: 0;
}
.Article-has-sideNav {
  margin-left: 0;
  position: relative;
}
@media only screen and (min-width: 48em), print {
}
@media only screen and (min-width: 80em) {
}
.Article-has-sideNav .Article-header,
.Article-has-sideNav .SplitContent {
  max-width: 1170px;
  margin: 0 auto;
  padding-right: 1.5625rem;
  padding-left: 1.5625rem;
}
@media only screen and (min-width: 80em) {
  .Article-has-sideNav .Article-header,
  .Article-has-sideNav .SplitContent {
    padding-right: 0;
    padding-left: 0;
  }
}
.Article-stickyNavListWrapper {
  position: absolute;
  left: 0;
}
.Article-teaser:hover {
  text-decoration: none;
}
hr.chapterline {
  border-width: 2px;
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: -1.5625rem;
  margin-left: -1.5625rem;
}
.Banner:not(.Banner--videoWrapper) .Banner-videoClip {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.Banner:not(.Banner--videoWrapper) .Banner-videoClip video {
  min-width: 100%;
}
@media only screen and (max-width: 34em) {
  .Banner:not(.Banner--videoWrapper) .Banner-videoClip video {
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}
/*#

<h1 class="Heading Heading--largeBold">Heading large bold</h1>
<h2 class="Heading Heading--large">Heading large</h2>
<h3 class="Heading Heading--mediumBold">Heading medium bold</h3>
<h4 class="Heading Heading--medium">Heading medium</h4>
<h5 class="Heading Heading--smallBold">Heading small bold</h5>
<h6 class="Heading Heading--small">Heading small</h6>
<h6 class="Heading Heading--xSmall">Heading xSmall</h6>

*/
h1,
h2,
h3,
h4,
h5,
h6,
.Heading {
  display: block;
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #18274a;
  font-weight: 400;
}
a:hover h1,
a:hover h2,
a:hover h3,
a:hover h4,
a:hover h5,
a:hover h6,
a:hover .Heading {
  color: #595857;
}
h1,
.Heading--h1 {
  font-weight: 300!important;
  font-size: 1.75rem;
}
@media only screen and (min-width: 48em), print {
  h1,
  .Heading--h1 {
    font-size: 2.1875rem;
    letter-spacing: -0.02em;
    line-height: 42px;
  }
}
@media only screen and (min-width: 57.5em), print {
  h1,
  .Heading--h1 {
    font-size: 2.8125rem;
    line-height: 60px;
  }
}
h2,
.Heading--h2,
.EPiServerForms .Form__Title {
  font-size: 1.25rem;
}
@media only screen and (min-width: 48em), print {
  h2,
  .Heading--h2,
  .EPiServerForms .Form__Title {
    font-size: 1.375rem;
    letter-spacing: -0.02em;
    line-height: 28px;
  }
}
@media only screen and (min-width: 57.5em), print {
  h2,
  .Heading--h2,
  .EPiServerForms .Form__Title {
    font-size: 1.5rem;
    line-height: 35px;
  }
}
h2.Heading--largeBold,
.Heading--h2.Heading--largeBold {
  font-size: 20px;
  font-weight: 600;
}
@media only screen and (min-width: 57.5em), print {
  h2.Heading--largeBold,
  .Heading--h2.Heading--largeBold {
    font-size: 24px;
  }
}
h3,
.Heading--h3 {
  font-size: 1.1875rem;
  line-height: 28px;
  letter-spacing: -0.02em;
  /*  font-size: @headingSizeH3--small;

    @media @bpMedium {
        font-size: @headingSizeH3--medium;
        letter-spacing: -0.02em;
        line-height: 26px;
    }

    @media @bpLarge {
        font-size: @headingSizeH3--large;
        line-height: 28px;
    }*/
}
.Heading-stroke:after {
  content: "";
  display: block;
  height: 2px;
  width: 35px;
  background: #ede9e9;
  margin: 1.5625rem 0 0.9375rem;
}
.Heading-stroke.Heading-stroke--blue:after {
  background: #18274a;
}
div[class*="Snow"] .Heading-stroke:after {
  background: #18274a;
}
.Heading-block {
  margin-bottom: 50px;
}
.Heading--semiBold {
  font-weight: 500;
}
.Heading--bold {
  font-weight: 600;
}
/*.Heading--medium,
h4 {
    font-size: @textSizeXLarge;
    font-weight: normal;

    @media @bpMedium {
        font-size: @textSizeXXLarge;
    }
}

.Heading--smallBold,
h5 {
    font-size: @textSizeLarge;
    font-weight: bold;

    @media @bpMedium {
        font-size: @textSizeXLarge;
    }
}

.Heading--small,
h6 {
    font-size: @textSizeLarge;
    font-weight: normal;

    @media @bpMedium {
        font-size: @textSizeXLarge;
    }
}

.Heading--xSmall {
    font-size: @textSizeMedium;
    font-weight: bold;
}

.Heading--underline {
    padding-bottom: @spaceXXSmall;

    @media @bpMedium {
        border-bottom: 1px solid @colorSnow;
    }
}

.Heading--strikethrough {
    margin: 0;
    text-align: center;

    span {
        background-color: @colorWhite;
        padding: 0 .6em;
        position: relative;
        z-index: 1;
    }

    &:after {
        border-bottom: 1px solid @colorSnow;
        bottom: .6em;
        content: "";
        display: block;
        position: relative;
    }
}

@media only screen and (max-width: unit(@widthMedium, em)) {
	.Heading--large-onlySm {
		font-size: unit((25px/@baseFontSize), em);
	}
}*/
.BlogList {
  padding-top: 3.75rem;
}
.BlogList .PageFilter {
  border-top: none;
  margin-bottom: 25px;
  padding-bottom: 0;
  padding-top: 0;
}
.BlogList .SearchBoxWrapper {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.BlogList .Search-container {
  margin-bottom: 70px;
  padding-left: 45px;
  padding-right: 45px;
  line-height: 167%;
}
.BlogList .Search form {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}
.BlogList date {
  display: block;
  color: #595857;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 167%;
}
.BlogList .Teaser-fullwidth {
  padding-left: 0;
  padding-right: 0;
}
.BlogList .Teaser-fullwidth .Teaser-imageWrapper {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.BlogList .Teaser-fullwidth .Teaser-image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  max-height: 300px;
}
.BlogList .Teaser-fullwidth .Teaser-heading {
  line-height: 130%;
}
@media only screen and (min-width: 57.5em), print {
  .BlogList .Teaser-fullwidth .Teaser-heading {
    font-size: 1.5rem;
  }
}
.BlogList .Teaser-fullwidth .Teaser-text {
  -webkit-line-clamp: 5 !important;
}
.BlogList .Teaser-heading {
  font-size: 1.1875rem;
  line-height: 28px;
}
.BlogList .SearchResult-list li {
  color: #18274a;
  font-weight: 300;
}
.BlogList .SearchResult-list .js-autoSuggest {
  padding-left: 0;
}
.BlogList .SearchResult-list a {
  color: #18274a;
  display: block;
  width: 100%;
  padding-bottom: 1rem;
  font-weight: 400;
}
.BlogList .SearchResult-list .SearchResults-Suggestion a:focus-visible,
.BlogList .SearchResult-list .SearchResults-Suggestion a:hover {
  outline: none;
  background-color: #e9e4e3;
  text-decoration: none;
  color: #18274a;
}
.BlogList .SearchResults-SuggestedWords,
.BlogList .SearchResults-Suggestions {
  list-style: none !important;
  padding-left: 0;
}
.BlogList .SearchResults-Suggestions li {
  border-top: 1px solid #E0E0E0;
}
.BlogList .SearchResults-Suggestions:last-child {
  border-bottom: 1px solid #E0E0E0;
}
.BlogList .SearchResults-Suggestions date {
  font-size: 10px;
  font-weight: 300;
  padding-top: 1rem;
  margin-top: 0;
  margin-bottom: 5px;
}
.BlogPage {
  max-width: 840px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}
.BlogPage .Article-associated {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.BlogPage .Article-associated .Category {
  margin-bottom: 0.9375rem;
}
.BlogPage .Article-associatedSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: right;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
}
@media only screen and (min-width: 34em), print {
  .BlogPage .Article-associatedSection {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    gap: 0;
  }
}
@media only screen and (min-width: 34em), print {
  .BlogPage .Article-associatedSection {
    display: initial;
  }
}
.BlogPage .Article-associatedSection .Button {
  margin-right: 0;
}
@media only screen and (min-width: 34em), print {
  .BlogPage .Article-associatedSection .Button {
    margin-top: 1rem;
  }
}
.BlogPage .Heading {
  margin-bottom: 14px;
}
@media only screen and (min-width: 34em), print {
  .BlogPage .Heading {
    margin-bottom: 35px;
  }
}
.BlogPage date {
  display: block;
  color: #595857;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 167%;
}
.BlogPage .ColorTeaser-heading {
  margin-bottom: 25px;
}
.BlogPage .Article {
  margin-bottom: 80px;
}
.BlogPage .Article .CallToAction {
  text-align: left;
}
.BlogPage .Article-mainImage {
  aspect-ratio: 3/1 !important;
}
.BlogPage .Article-header {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}
@media only screen and (min-width: 34em), print {
  .BlogPage .Article-header {
    margin-bottom: 30px;
  }
}
.BlogPage .Column-content {
  padding-left: 0;
  padding-right: 0;
}
.BlogPage .Column-text {
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.BlogPage .Column-text p {
  max-width: initial;
}
.BlogPage .Column-image + .Column-text .Heading,
.BlogPage .Column-video + .Column-text .Heading {
  padding-top: 5.5625rem;
}
.BlogPage .CallToAction {
  text-align: right;
  margin-bottom: 0;
}
.BlogPage .CallToAction .Link {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}
.ColorTeaser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow: hidden;
  -webkit-transition: ease-in-out 0.4s;
  transition: ease-in-out 0.4s;
  margin-bottom: 1.875rem;
  position: relative;
}
@media only screen and (min-width: 57.5em), print {
  .ColorTeaser {
    margin-bottom: 0;
    height: 100%;
  }
}
.ColorTeaser .ColorTeaser-heading {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-transition: color ease-in-out 0.9s;
  transition: color ease-in-out 0.9s;
  line-clamp: 3;
  box-orient: vertical;
}
.ColorTeaser .ColorTeaser-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ColorTeaser:hover {
  text-decoration: none;
  color: inherit;
}
.ColorTeaser:hover .ColorTeaser-image {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ColorTeaser:hover .Category-item {
  color: #fff;
  opacity: 0.5;
}
.ColorTeaser--themeMidnight2 .Heading {
  color: #fff;
}
.ColorTeaser--themeMidnight2:hover {
  color: #e9e4e3;
}
.ColorTeaser--themeMidnight2:hover .Heading {
  color: #e9e4e3;
}
.ColorTeaser--themeCliff .Heading {
  color: #fff;
}
.ColorTeaser--themeCliff:hover {
  color: #e9e4e3;
}
.ColorTeaser--themeCliff:hover .Heading {
  color: #e9e4e3;
}
.ColorTeaser--themeBlue1 .Heading {
  color: #fff;
}
.ColorTeaser--themeBlue1:hover {
  color: #e9e4e3;
}
.ColorTeaser--themeBlue1:hover .Heading {
  color: #e9e4e3;
}
.ColorTeaser .Category {
  padding: 0 1.5625rem;
  margin-bottom: 1.5625rem;
}
@media only screen and (min-width: 48em), print {
  .ColorTeaser .Category {
    padding: 0 2.5rem;
    margin-bottom: 1.5625rem;
  }
}
.ColorTeaser-content {
  padding: 1.5625rem;
}
@media only screen and (min-width: 48em), print {
  .ColorTeaser-content {
    padding: 2.5rem;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}
.ColorTeaser-heading {
  margin-bottom: 25px;
}
.ColorTeaser-text {
  margin-bottom: 20px;
}
.ColorTeaser-imageWrapper {
  min-height: 150px;
  overflow: hidden;
  aspect-ratio: 3/2;
  -o-object-fit: cover;
     object-fit: cover;
}
.ColorTeaser-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: -webkit-transform ease-in-out 0.4s;
  transition: -webkit-transform ease-in-out 0.4s;
  transition: transform ease-in-out 0.4s;
  transition: transform ease-in-out 0.4s, -webkit-transform ease-in-out 0.4s;
  height: 100%;
}
@media only screen and (min-width: 57.5em), print {
  .PageList--Teaser .Grid--equalHeight .ColorTeaser {
    height: auto!important;
    cursor: pointer;
  }
}
.Column-video .Asset-video {
  aspect-ratio: 3/2;
}
.Column-video .Asset-video iframe {
  aspect-ratio: 3/2 !important;
}
.Column-video .Asset-video video {
  aspect-ratio: 3/2 !important;
  background-color: #000;
}
.Column-video .Asset-video .Asset-video--wrapper {
  overflow: hidden;
}
.Column-video .Asset-video .Asset-video--wrapper img.Asset-video--poster {
  width: auto;
  height: calc(100% - 0.44em);
}
.Contact-topCategory {
  /*  border-bottom: 1px solid @colorBorder;
    padding-bottom: @spaceLarge;
    margin-bottom: @spaceLarge;*/
}
.Contact-topCategory a:hover {
  text-decoration: none;
}
.Contact-subWrapper {
  max-width: 1220px;
  margin: 0 auto;
}
@media only screen and (min-width: 57.5em), print {
  .Contact-subWrapper {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.Contact-subCategory {
  margin-left: 15px;
  margin-right: calc(-50vw + 50%);
}
@media only screen and (min-width: 57.5em), print {
  .Contact-subCategory {
    margin: 0;
  }
}
.Contact-subCategory--border:before {
  content: "";
  width: 100%;
  max-width: 1220px;
  height: 1px;
  background: #fff;
  display: block;
  padding-top: 1.25rem;
  margin-top: 1.875rem;
  border-top: 1px solid #ede9e9;
}
.Contact-subCategory .tns-inner {
  margin-left: 0 !important;
}
.Contact-formWrapper {
  border-top: 1px solid #ede9e9;
  margin-top: 3.75rem;
  padding-bottom: 3.75rem;
}
.Contact-form {
  max-width: 768px;
}
.Accordion--contactItem .Accordion-panel {
  padding: 1.5625rem 1.875rem;
}
.ContactInfo-fax {
  display: inline-block;
  max-width: 16px;
}
.ContactInfo-icon {
  margin-right: 6px;
}
.Teaser--contactInfo {
  margin-top: 3.75rem;
}
.Teaser--contactInfo .Teaser {
  height: 100%;
}
.Teaser--contactInfo .ContactInfo-item {
  border-bottom: 0;
}
.ContactInfo {
  background-color: #fbfaf9;
  color: #18274a;
  font-size: 1rem;
  width: 100%;
  height: 100%;
}
.ContactInfo p {
  font-size: 1rem;
  word-break: break-all;
}
.ContactInfo-desktop-container {
  width: 100%;
}
@media screen and (max-width: 919px) {
  .ContactInfo-desktop-container {
    display: none !important;
  }
}
.ContactInfo-mobile-container {
  margin-bottom: 25px;
  width: 100%;
}
@media screen and (min-width: 920px) {
  .ContactInfo-mobile-container {
    display: none;
  }
}
.ContactInfo-header + .ContactInfo-item {
  padding-top: 0;
}
.ContactInfo svg {
  color: #18274a;
}
.ContactInfo-item {
  padding: 20px 15px;
  border-bottom: 1px solid #fff;
}
.ContactInfo-header {
  padding: 20px 0 0 15px;
}
.ContactInfo-officeName {
  position: relative;
}
.ContactInfo-officeName svg {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 2px;
}
.ContactInfo-officeName span {
  display: block;
  margin-right: 25px;
}
.Carousel-contact {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@media only screen and (min-width: 57.5em), print {
  .Carousel-contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
  }
  .Carousel-contact .Carousel-subCategory {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    padding: 12.5px 20px;
  }
}
.ctaBanner .Banner {
  aspect-ratio: unset !important;
  padding-inline: 25px;
}
@media only screen and (min-width: 73.125em) {
  .ctaBanner .Banner {
    padding-inline: 0;
  }
}
.ctaBanner .Banner .Banner-text {
  width: auto;
}
.ctaBanner .Banner .Banner-content {
  padding-inline: 0;
}
@media only screen and (min-width: 73.125em) {
  .ctaBanner .Banner .Banner-content {
    padding-inline: 0;
  }
}
.CustomScroll {
  scrollbar-color: #333 #fff;
  scrollbar-width: thin;
}
.CustomScroll::-webkit-scrollbar,
.CustomScroll::-webkit-scrollbar-track,
.CustomScroll::-webkit-scrollbar-thumb {
  z-index: 10;
  width: 4px;
}
.CustomScroll::-webkit-scrollbar-track {
  border-radius: 3px;
}
.CustomScroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #333;
  border-radius: 3px;
}
.CustomScroll::-webkit-scrollbar-thumb:hover {
  background-color: #e94f35;
}
.CustomScroll::-webkit-input-placeholder,
.CustomScroll::-webkit-scrollbar-track,
.CustomScroll::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-clip: padding-box;
}
.CustomScroll::-webkit-scrollbar-corner {
  background-color: transparent;
}
@media only screen and (min-width: 48em), print {
  .DefinitionList {
    padding-left: 0;
    padding-right: 0;
  }
}
.DefinitionList .Page-container {
  max-width: 48rem;
  padding-left: 0;
  padding-right: 0;
}
.DefinitionList-row {
  border-top: 1px solid #e9e4e3;
}
.DefinitionList-row .Button,
.DefinitionList-row a {
  margin-top: 0;
  margin-left: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.DefinitionList-column--second {
  padding-top: 1.875rem;
}
@media only screen and (min-width: 48em), print {
  .DefinitionList-column--second {
    padding-top: 0;
    padding-left: 2.5rem;
  }
}
.DefinitionList .DefinitionList-item {
  padding-top: 1.875rem;
}
.DefinitionList .DefinitionList-item--name {
  color: #18274a;
  font-weight: 600;
}
.DefinitionList .DefinitionList-item--value {
  padding-top: 0.9375rem;
  font-weight: 300;
  font-size: 0.875rem;
}
@media only screen and (min-width: 48em), print {
  .DefinitionList .DefinitionList-item--value {
    padding-top: 1.875rem;
  }
}
.DeviceNav-wrapper ul {
  padding: 6px 0;
}
.Document {
  border-bottom: 1px solid #ede9e9;
  margin-top: 1.5625rem;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  list-style: none;
}
.Document:last-child {
  border: none;
}
.Document-header .Document-downloadLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #18274a;
}
.Document-header .Document-downloadLink .Heading {
  margin-bottom: 0;
  display: inline-block;
}
.Document-header .Document-downloadLink:hover {
  text-decoration: none;
}
.Document-header .Document-downloadLink:hover .Heading {
  color: #e56b78;
}
.Document-header .Document-downloadLink:hover .Document-download {
  background: #e56b78;
  text-decoration: none;
  color: #fff;
}
.Document-download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 100%;
  aspect-ratio: 1;
  width: 40px;
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Document-download:hover span {
  color: #fff;
}
.DownloadList-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  border-bottom: 1px solid #ede9e9;
  border-top: 1px solid #ede9e9;
}
.DownloadList-name {
  font-size: 19px;
  color: #18274a;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.DownloadList-description {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
@media only screen and (min-width: 48em), print {
  .DownloadList-description {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
}
.DownloadList-link {
  color: #18274a;
  font-size: 0.875rem;
  display: block;
  font-weight: 700;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  margin-left: auto;
}
@media only screen and (min-width: 48em), print {
  .DownloadList-link {
    margin-left: 0;
  }
}
.DownloadList-link:after {
  font-family: Getinge-icon;
  content: "\e908";
  color: #fff;
  height: 40px;
  width: 40px;
  background: #18274a;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  border-radius: 100%;
  margin-left: 0.5rem;
}
.DownloadList-link:hover {
  text-decoration: none;
}
/* fix for downloads lists getting double indented when used in cloumn/banner block */
.TextIndent .TextIndent-content .DownloadList.Main-content {
  padding: 0;
}
.TextIndent .TextIndent-content .DownloadList .DownloadList-item {
  padding-left: 0;
}
/* limit forms to 768px or they will become too wide */
.Page-container > .EPiServerForms {
  max-width: 768px;
}
.EPiServerForms {
  width: 100%;
}
.EPiServerForms .Form__Element {
  position: relative;
  margin-bottom: 45px;
}
.EPiServerForms .Form__Element .Form-subHeading {
  color: #18274a;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.01em;
  margin-top: 55px;
  margin-bottom: 35px;
}
@media only screen and (min-width: 48em), print {
  .EPiServerForms .Form__Element .Form-subHeading {
    margin-top: 65px;
  }
}
.EPiServerForms a {
  color: #e56b78;
}
.EPiServerForms a:hover {
  color: #e56b78;
}
.EPiServerForms .Form__Title {
  margin: 0;
  display: block;
}
.EPiServerForms .Form__Title:after {
  content: "";
  display: block;
  height: 2px;
  width: 35px;
  background: #18274a;
  margin: 1.5625rem 0 0.9375rem;
  margin-left: 0;
}
.EPiServerForms .Form__Description {
  margin: 1.5625rem 0 0;
  max-width: 600px;
}
.EPiServerForms .FormCaptcha__Image {
  margin-top: 5px;
}
.EPiServerForms .EPiForm-label {
  display: inline-block !important;
}
.EPiServerForms h1 {
  color: #18274a;
  line-height: 1.25em;
}
.EPiServerForms input[type='checkbox'] {
  width: 20px;
  height: 20px;
  vertical-align: sub;
}
.EPiServerForms label,
.EPiServerForms span,
.EPiServerForms .Form__Element__Caption {
  color: #000;
  font-size: 13px;
  line-height: 21px;
  display: block;
}
.EPiServerForms label .FloatedLabel-label {
  max-width: 75%;
}
.EPiServerForms span.Radio {
  margin-bottom: 20px;
}
.EPiServerForms input,
.EPiServerForms textarea,
.EPiServerForms select {
  width: 100%;
  display: block;
  font-size: 1rem;
  font-weight: normal;
  line-height: 21px;
  color: #192849;
  border: 1px solid #c0c0c0;
  height: 58px;
  padding-left: 24px;
  color: #18274a;
  border-radius: 2px;
}
.EPiServerForms input:focus,
.EPiServerForms textarea:focus,
.EPiServerForms select:focus {
  border: 1px solid #c0c0c0;
  background: #FAFAFA;
}
.EPiServerForms input[type=radio] {
  height: auto;
}
.EPiServerForms .Form__Element.FormTextbox {
  display: block;
  position: relative;
}
.EPiServerForms .Form__Element.FormTextbox:after {
  position: absolute;
  content: "Optional";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  right: 15px;
  top: 50%;
  font-size: 11px;
  color: #595857;
  background: #C0C0C0;
  padding: 0 10px;
  border-radius: 5px;
  height: 24px;
  margin-top: -12px;
}
.EPiServerForms .Form__Element.FormTextbox.ValidationRequired:after {
  content: "";
  display: none;
}
.EPiServerForms .Form__Element.FormTextbox.FormTextbox--Number:after {
  right: 25px;
}
.EPiServerForms .FormFileUpload .FormFileUpload__Input {
  background: #FAFAFA;
  padding-top: 15px;
  padding-bottom: 15px;
}
.EPiServerForms .FormCaptcha {
  background: #FAFAFA;
  padding: 15px 24px;
}
.EPiServerForms .FormCaptcha input {
  background: #fff;
  margin-top: 20px;
}
.EPiServerForms textarea {
  height: 250px;
  min-height: 80px;
  padding-top: 20px;
}
.EPiServerForms .Select {
  border-bottom: none;
}
.EPiServerForms select option {
  font-weight: normal;
  display: block;
  white-space: pre;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}
.EPiServerForms .Select:after {
  top: unset;
  bottom: 16px;
  right: 18px;
  background-color: transparent;
  background-image: none;
  content: "\e906";
  font-family: 'Getinge-icon';
  font-size: 7px;
  width: auto;
}
.EPiServerForms .FormChoice__Input--Radio,
.EPiServerForms .FormChoice__Input--Checkbox {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: auto;
  padding: 0;
}
.EPiServerForms button.FormSubmitButton.Form__Element {
  -webkit-transition: 0.15s ease-in;
  transition: 0.15s ease-in;
  background: #18274a !important;
  border: 1px solid #18274a !important;
  color: #fff;
  margin-top: 15px;
  border-radius: 30px;
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
}
@media only screen and (min-width: 48em), print {
  .EPiServerForms button.FormSubmitButton.Form__Element {
    min-width: 210px;
    width: auto;
  }
}
.EPiServerForms button.FormSubmitButton.Form__Element:hover {
  border-color: #495a6b !important;
  background-color: #495a6b !important;
}
@media only screen and (min-width: 48em), print {
  .EPiServerForms .FormResetButton {
    min-width: 210px;
    height: 40px;
    width: auto;
  }
}
.EPiServerForms .Form__Element__ValidationError {
  font-size: 0.75rem;
  color: #CF6A6E;
  float: right;
  position: absolute;
  bottom: -1.5rem;
  left: 16px;
}
.EPiServerForms .ValidationFail.FormChoice label {
  border: 1px solid #CF6A6E;
}
.EPiServerForms .ValidationFail input,
.EPiServerForms .ValidationFail input[type='checkbox'],
.EPiServerForms .ValidationFail textarea {
  border: 2px solid #CF6A6E;
}
.EPiServerForms .ValidationFail select {
  border: 2px solid #CF6A6E;
}
.EPiServerForms fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.EPiServerForms input[type='radio'] {
  margin-right: 15px;
}
.EPiServerForms input[type='radio']:after {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  top: -7px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #ede9e9;
}
.EPiServerForms input[type='radio']:checked:after {
  background-repeat: no-repeat;
  background-position: center 4px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAG2SURBVHgBpZLPSwJBFMffrEvtqpHWwaSIgojwFEHnokt0CgLv/QFCnSPILnYNkqKgqGuHCDp26BJBRT8v/RBKMEnSzMzMH+tM75kbVmYLPfju7My872fe/GBQIW7Hx22ypnRzkPqpz0CctvpnNivlsu8DIc/EGA57AYTtS6KAIJdgunXOt/orAM1ohCmoEhKAt9nvm/4BuPRMtluA34CBKHAYaJv37ZSAH2AVuBcMBpP4qO4tflwul4x7HDYMEIxyTTqARaPRGsag3jCAMcqtJX8RkM/n5QIXL0YBXIgkNrJeASQSCfacfds2CkjmMpRLF8AIIOx2Oz98CG8aBSyf7c+Tj1SsQFGUvGd7azeQiC39ZQ7EY0uzB3tBp9OZI0DxJFOpFGiaZlo7P7rodbbEmyx1nbIkWcuNeEap00h4YWh9ZRG7T+h5pWH9IVGrohpRDSirf3Ckr8Nm68A62VHk7mTj+uLq+D4UwblHAggh0ngb4nMFt9ttcjgcFlVVm7HbhepB9ZZE/y4UzVkpF8pWLg86kxqqxmw2q+l0Wk/UUJmSsigOVYKgEr5OAtFjUUpQU4UF/x/vB9uP/267Ub0AAAAASUVORK5CYII=');
}
.EPiServerForms input[type='checkbox'] {
  margin-right: 15px;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.EPiServerForms input[type='checkbox']:after {
  width: 24px;
  height: 24px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #ede9e9;
  border-radius: 2px;
}
.EPiServerForms input[type='checkbox']:checked:after {
  content: '';
  background-repeat: no-repeat;
  background-position: center 4px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAANCAYAAACgu+4kAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABcSURBVHgBzZDBDQAQEAQ30ciVoiMlaEUnSlGDCrhLSEQ82JdJ1kNuNhzA4+1w4IiapKkgMLlpikbwyKeyXQRWNvIYiIw8X1AOJU9/3kuoha0l9LZliJS8lsjtcAeDsyQ85swK/wAAAABJRU5ErkJggg==');
}
.EPiServerForms .FormChoice label {
  font-size: 1rem;
  color: #595857;
  padding: 10px 0;
  display: inline-block;
}
.EPiServerForms .FormChoice.ValidationFail label {
  border: none;
}
.EPiServerForms .FormChoice.ValidationFail label input:after {
  border-color: #CF6A6E;
}
.EPiServerForms .FormChoice--Image fieldset {
  border: 1px solid #ede9e9;
  padding: 24px;
}
.EPiServerForms .FormChoice--Image .FormChoice--Image__Item__Caption {
  display: inline-block;
}
.EPiServerForms .FormChoice--Image img {
  margin-top: 20px;
}
.EPiServerForms .Form-subHeading + .Form__Element .FloatedLabel {
  -webkit-transition: margin ease-in-out 0.3s;
  transition: margin ease-in-out 0.3s;
}
.EPiServerForms .Checkbox-withRichTextLabel label {
  display: inline;
}
.EPiServerForms .Checkbox-withRichTextLabel label p {
  margin: 0;
  padding-left: 2em;
  top: 2px;
  position: relative;
}
.EPiServerForms Input,
.EPiServerForms input[type=email] {
  width: 100%;
  display: block;
  font-size: 1rem;
  font-weight: normal;
  line-height: 21px;
  color: #192849;
  border: 1px solid #c0c0c0;
  height: 58px;
  padding-left: 24px;
  color: #18274a;
  border-radius: 2px;
}
.EPiServerForms Input:focus,
.EPiServerForms input[type=email]:focus {
  border: 1px solid #c0c0c0;
  background: #FAFAFA;
}
.EPiServerForms .Form__Original__ParagraphText {
  display: none;
}
.EPiServerForms .u-block.hide {
  display: none !important;
}
.FloatedLabel.is-active ~ .Form-subHeading {
  border: 1px solid red;
}
.FloatedLabel.is-active .FloatedLabel-label {
  border-radius: 4px;
  border: 1px solid #c0c0c0;
  /*padding: 0 4px;*/
}
.FormChoice.ValidationRequired.ValidationFail input,
.FormChoice.ValidationRequired.ValidationFail input[type='checkbox'],
.FormChoice.ValidationRequired.ValidationFail textarea {
  border: none;
}
.FormChoice.ValidationRequired.ValidationFail .Checkbox-label,
.FormChoice.ValidationRequired.ValidationFail .Radio-label {
  border: none;
}
.FormChoice.ValidationRequired.ValidationFail .Checkbox-label:before,
.FormChoice.ValidationRequired.ValidationFail .Radio-label:before {
  border: 2px solid #CF6A6E;
}
.Form__NavigationBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 80px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Form__NavigationBar .Form__NavigationBar__ProgressBar--Text span {
  display: inline-block;
  font-weight: 700;
}
.Form__NavigationBar .Form__NavigationBar__ProgressBar--Text .Form__NavigationBar__ProgressBar__CurrentStep {
  text-decoration: underline;
}
.Form__NavigationBar button:disabled {
  background: gray;
  border-color: gray;
}
.Form__Element.FormRange label {
  display: block;
  margin-bottom: 10px;
}
.Form__Element.FormRange span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Form__Element.FormRange input {
  width: 90%;
}
input[type=range] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */
  height: 6px;
  background: #fff;
  border-radius: 10px;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background: #18274a;
  cursor: pointer;
  margin-top: -3px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}
/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background: #18274a;
  cursor: pointer;
}
#form .u-marginTxlg-desktop {
  margin-top: 0 !important;
}
#form .Heading--h4 {
  margin-top: 70px !important;
}
@media only screen and (min-width: 48em), print {
  #form .Heading--h4 {
    margin-top: 85px !important;
  }
}
.Form__Status {
  margin-bottom: 25px;
}
/* Event page */
.EventPage-header {
  margin: 0 0 3.75rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #ede9e9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media only screen and (min-width: 48em), print {
  .EventPage-header {
    margin: 2.5rem 0 3.75rem;
  }
}
.EventPage-header .EventPage-header-date {
  color: #18274a;
}
.EventPage-header .EventPage-header-location {
  color: #bfb1ac;
  display: block;
}
.EventPage-header .EventPage-header-time:not(:last-child):after {
  content: ' |';
}
.EventPage-header .EventPage-header-cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.5625rem;
}
@media only screen and (min-width: 48em), print {
  .EventPage-header .EventPage-header-cta {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-top: 0;
  }
}
.EventPage ul {
  padding-left: 1rem;
}
.EventPage .List {
  padding: 0;
}
.EventPage .List .List-item {
  list-style: none;
}
.EventPage .List .List-item a:after {
  content: "\e908";
  font-family: Getinge-icon;
  vertical-align: -1px;
  margin-left: 8px;
  font-size: 13px;
}
.Event-allLink {
  margin-left: auto;
  margin-right: 0;
}
@media only screen and (min-width: 57.5em), print {
  .Event-details {
    border-left: 4px solid #ede9e9;
    padding-left: 1.25rem;
  }
}
.Event-details--label {
  display: inline-block;
  color: #18274a;
}
.Event-details--text {
  display: inline-block;
}
.Event-relatedLinks {
  margin-top: 1.25rem;
}
@media only screen and (min-width: 57.5em), print {
  .Event-relatedLinks {
    border-left: 4px solid #ede9e9;
    padding-left: 1.25rem;
  }
}
.Event-relatedLinks ul {
  margin: 0;
}
.EventList {
  width: 100%;
  max-width: 870px;
  margin: 0 auto;
}
.EventList-wrapper .Filter-header {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.EventList-wrapper .Filter-header p {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
.EventList-item {
  padding: 1.5625rem;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background-color ease-in-out 0.5s;
  transition: background-color ease-in-out 0.5s;
}
.EventList-item:hover {
  background-color: rgba(232, 227, 226, 0.5);
}
.EventList-item a:hover {
  text-decoration: none;
}
.EventList-label {
  text-transform: uppercase;
  padding: 1px 5px;
  font-size: 0.625rem;
  background: #ececeb;
  color: #18274a;
  margin-bottom: 10px;
}
.EventList-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media only screen and (min-width: 48em), print {
  .EventList-header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.EventList-itemInfo {
  color: #595857;
  font-size: 1.1875rem;
  width: 100%;
  margin-bottom: 1.5625rem;
}
@media only screen and (min-width: 48em), print {
  .EventList-itemInfo {
    width: auto;
    margin-bottom: 0;
  }
}
.EventList-itemInfoName {
  color: #18274a;
}
.EventList-itemInfoPlaceTime {
  margin-top: 1.5625rem;
  color: #595857;
  font-size: 1rem;
}
.EventList-itemInfoPlaceTime span:before {
  content: "|";
  padding-right: 0.3rem;
  padding-left: 0.3rem;
}
.EventList-itemInfoPlaceTime span:first-child:before {
  content: none;
}
.EventList-loadMore {
  margin: 40px 0;
  text-align: center;
}
.List-itemSubHeading {
  font-size: 1rem;
  margin: 0;
  max-width: 90%;
}
.List-itemSubHeading--own {
  margin-top: 0.625rem;
  max-width: 630px;
}
.EventList-itemCta {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.EventList-slider .TinySlider-header {
  height: 45px!important;
  top: -10px;
}
.EventList-slider .tns-outer {
  margin-left: 0 !important;
}
.EventList-slider .tns-item {
  margin-right: 25px;
}
.EventList-slider .tns-item .UpToDate-item {
  padding-left: 0;
}
.EventList-slider .tns-item:last-child .UpToDate-item {
  border-right: 0;
}
.UpToDate-item {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-right: 1px solid #ede9e9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
  min-height: 145px;
}
@media only screen and (min-width: 48em), print {
  .UpToDate-item {
    min-height: 190px;
    padding: 0 20px;
  }
}
@media only screen and (min-width: 57.5em), print {
  .UpToDate-item {
    padding: 0 25px;
  }
}
.UpToDate-item .UpToDate-date {
  color: #595857;
  font-weight: 200;
}
.UpToDate-item .Heading {
  max-width: 90%;
}
.UpToDate-item .UpToDate-typeText {
  color: #595857;
  font-size: 13px;
}
@media only screen and (min-width: 57.5em), print {
  .UpToDate-item .UpToDate-typeText {
    font-size: 15px;
  }
}
.UpToDate-item .Heading,
.UpToDate-item .UpToDate-date {
  font-size: 15px;
  line-height: 22px;
}
@media only screen and (min-width: 57.5em), print {
  .UpToDate-item .Heading,
  .UpToDate-item .UpToDate-date {
    font-size: 19px;
    line-height: 28px;
    letter-spacing: -0.01em;
  }
}
.UpToDate-item:hover {
  text-decoration: none;
}
.Filter-header {
  text-align: center;
}
@media only screen and (min-width: 57.5em), print {
  .Filter-header:not(.Filter-header--listBlock) .Filter--listBlock {
    margin-left: auto;
    margin-right: auto;
  }
}
.Filter-header .Filter {
  margin-bottom: 3.75rem;
  /* for multi-level filtering */
}
.Filter-header .Filter .Button {
  margin: 0 12px 12px 0;
  padding: 0 20px;
  border-width: 1px;
  min-height: 30px;
  height: auto;
}
.Filter-header .Filter-subCategory {
  max-width: 100%;
  display: none;
  text-align: left;
}
.Filter-header .Filter-subCategory .Checkbox:has(input:focus-visible) {
  outline-offset: 4px;
}
.Filter-header .Filter-subCategory.is-active {
  display: block;
}
.Filter-header .Filter-subCategory .Checkbox,
.Filter-header .Filter-subCategory .Searchbox {
  display: inline-block;
  margin: 0.3rem 0.2rem;
  color: #18274a;
}
.Filter-header .Filter-subCategory .Checkbox input,
.Filter-header .Filter-subCategory .Searchbox input {
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: middle;
  outline: 2.5px solid #e9e4e3;
  outline-offset: -2px;
  -webkit-clip-path: circle(66% at 50% 50%);
          clip-path: circle(66% at 50% 50%);
}
.Filter-header .Filter-subCategory .Checkbox input:checked,
.Filter-header .Filter-subCategory .Searchbox input:checked {
  outline: none !important;
}
.Filter-header .Filter-subCategory .Checkbox label,
.Filter-header .Filter-subCategory .Searchbox label {
  vertical-align: middle;
  margin-right: 1.2rem;
}
.Filter-header .Filter .Searchbox {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  background-color: transparent;
  border-color: #e9e4e3;
}
.Filter-header .Filter .Searchbox input {
  height: 100%;
  border: 0;
  padding-left: 0.625rem;
  font-size: 0.75rem;
}
.Filter-header .Filter .Searchbox input:focus {
  background-color: transparent;
}
.Filter-header--listBlock {
  max-width: 100%;
  text-align: left;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}
.Filter-header--listBlock .Heading {
  min-height: 35px;
  max-width: 90%;
}
.Filter-header--listBlock .Filter--listBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  padding-bottom: 3.75rem;
  border-bottom: 1px solid #e0e0e0;
}
.Filter-header--listBlock .Filter--listBlock .Checkbox:before {
  content: "|";
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}
.Filter-header--listBlock .Filter--listBlock .Checkbox:first-child:before {
  content: none;
}
.Filter-header--listBlock .Filter--listBlock .Checkbox-label {
  color: #18274a;
  padding: 0;
}
.Filter-header--listBlock .Filter--listBlock .Checkbox.is-active .Checkbox-label {
  font-weight: bold;
}
.Filter-header--listBlock .Filter--listBlock .Checkbox .Checkbox-label:after,
.Filter-header--listBlock .Filter--listBlock .Checkbox .Checkbox-label:before {
  content: none;
}
.Filter-header--listBlock .Filter--listBlock .Checkbox.is-active .Checkbox-label:after,
.Filter-header--listBlock .Filter--listBlock .Checkbox.is-active .Checkbox-label:before {
  content: none;
}
.Footer--primary {
  overflow: visible;
}
.Footer--secondary .Footer-content {
  text-align: left;
}
.Footer-nav .Footer-navColumn--last > .PageNav-item {
  margin: 0;
}
.Footer-nav .Footer-navColumn--last .PageNavItem-parent {
  position: relative;
  top: 0px;
}
.Footer-nav .Footer-navColumn--last .PageNavItem-parent::before {
  position: relative;
  top: 1px;
}
.Footer-nav .Footer-navColumn--last .PageNavItem-parent span {
  font-family: AdelleSans;
}
.Footer-nav .Footer-navColumn--last .PageNavItem-parent--childLess {
  height: 21px;
}
.Footer-nav .Footer-navColumn--last > .RegionSelect {
  cursor: pointer;
}
.Footer-nav .Footer-navColumn--last > .RegionSelect:focus-within .PageNav-sub {
  display: block;
}
.Footer-nav .Footer-navColumn--last > .RegionSelect:hover {
  color: #e9e4e3;
}
.Footer-nav .Footer-navColumn--last > .RegionSelect .PageNav-sub {
  -webkit-transform: translateY(calc(-100% - 10px));
          transform: translateY(calc(-100% - 10px));
}
.Footer-nav .Footer-navColumn--last > .RegionSelect .PageNav-sub::before {
  content: '';
}
.Footer-nav .Footer-navColumn--last > .RegionSelect .PageNav-sub::after {
  content: '';
  height: 24px;
  width: 100%;
  background: transparent;
  display: block;
}
.Footer-nav .Footer-navColumn--last > .RegionSelect .PageNav-scrollContentWrapper {
  background: #fff;
  border-radius: 3px;
  padding: 16px 8px 16px 0;
}
.Footer-nav .Footer-navColumn--last > .RegionSelect .PageNav-scrollContent {
  font-family: AdelleSans;
  max-height: 400px;
  overflow-y: scroll;
  z-index: 1;
}
.Footer-nav .Footer-navColumn--last > .RegionSelect .PageNav-scrollContent > ul {
  padding: 0;
  margin: 0;
  max-height: none;
}
.Header {
  margin: 0;
  max-width: 100%;
  padding: 0;
  margin: 0 auto;
}
.Breadcrumb,
.Header-navigation {
  max-width: 1220px;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: auto;
  margin-right: auto;
}
.Header-navigation {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.Header-tertiaryNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  height: 36px;
  width: 100%;
  z-index: 1;
  background: rgba(73, 90, 106, 0.6);
}
.Header-tertiaryNav > .PageNav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  width: 1220px;
  max-width: 1220px;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: auto;
  margin-right: auto;
}
.Header-tertiaryNav > .PageNav-list > .RegionSelect .PageNav-scrollContentWrapper {
  background: #fff;
  border-radius: 3px;
  padding: 16px 8px 16px 0;
}
.Header-tertiaryNav > .PageNav-list > .RegionSelect .PageNav-scrollContent {
  font-family: AdelleSans;
  max-height: 400px;
  overflow-y: scroll;
  z-index: 1;
}
.Header-tertiaryNav > .PageNav-list > .RegionSelect .PageNav-scrollContent > ul {
  padding: 0;
  margin: 0;
  max-height: none;
}
.Header-tertiaryNav--hr {
  margin-left: 5px;
  margin-right: 5px;
  height: 10px;
  width: 1px;
}
.Header-tertiaryNav .PageNav-item {
  margin: 0 10px;
  font-size: 12px;
}
.Header-tertiaryNav .PageNav-item:first-child {
  padding: 4px 16px;
  border-right: 1px solid #fff;
}
.Header-tertiaryNav .PageNav-item:only-child {
  margin: 0;
  padding: 4px 0;
  border: 0;
}
.Header-tertiaryNav .PageNavItem-parent {
  position: relative;
  top: 0px;
  right: 6px;
}
.Header-tertiaryNav .PageNavItem-parent::before {
  position: relative;
  top: 1px;
  padding: 2px 8px;
}
.Header-tertiaryNav .PageNavItem-parent span {
  font-family: AdelleSans;
}
.Header-tertiaryNav .PageNavItem-parent--childLess {
  height: 21px;
}
.Header-wrapper--inverted .Header-tertiaryNav {
  background: #e9e4e3;
}
.Header-wrapper--inverted .Header-tertiaryNav > .PageNav-list .PageNav-item:first-child {
  padding: 4px 16px;
  border-right: 1px solid #18274a;
}
.Header-wrapper--inverted .Header-tertiaryNav > .PageNav-list .PageNav-item:only-child {
  margin: 0;
  padding: 4px 0;
  border: 0;
}
.Header-wrapper--inverted .Header-tertiaryNav > .PageNav-list > .RegionSelect .PageNav-scrollContentWrapper {
  background: #18274a;
}
.Header-wrapper--inverted .Header-tertiaryNav > .PageNav-list > .RegionSelect .PageNav-scrollContent {
  scrollbar-color: #fff #18274a;
}
.Header-wrapper--inverted .Header-tertiaryNav > .PageNav-list > .RegionSelect .PageNav-scrollContent::-webkit-scrollbar-thumb {
  background-color: #fff;
}
.Header-wrapper--inverted .Header-tertiaryNav > .PageNav-list > .RegionSelect .PageNav-scrollContent::-webkit-scrollbar-thumb:hover {
  background-color: #e94f35;
}
@media only screen and (min-width: 48em), print {
  .Iframe--reduced {
    max-width: 813.33333333px;
  }
}
/* 
    pin details are displayed next to the image map instead of where the mouse pointer is currently
    in medium and lower bp, the pin details are hidden and a separate pin list is displayed below the image instead

*/
.ImageMapper {
  padding-top: 1.25rem;
}
.ImageMapper.BlockMargin--large {
  padding-top: 0;
}
.ImageMapper-content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.ImageMapper-hero {
  margin-bottom: 1.5625rem;
}
.ImageMapper-hero:after {
  content: "";
  height: 2px;
  width: 35px;
  background: #31b7bc;
  display: block;
  margin-top: 1.5625rem;
}
.ImageMapper-heading {
  font-weight: bold;
}
.ImageMapper-heading.Heading--large {
  font-size: 27px;
  line-height: 44px;
}
.ImageMapper-heading.Heading--medium {
  font-size: 27px;
  line-height: 44px;
  font-weight: normal;
}
.ImageMapper-heading.Heading--textCenter {
  width: 100%;
  text-align: center;
}
.pin-heading {
  font-size: 19px;
  line-height: 28px;
  letter-spacing: -1%;
  color: #18274a;
}
.pin-image,
.pinDetails--image {
  margin-bottom: 1.5625rem;
}
.pin-preamble {
  font-size: 1.1875rem;
  line-height: 30px;
}
.pin-link:after {
  font-family: Getinge-icon;
  content: "\e908";
  -webkit-transition: left 0.15s ease-in;
  transition: left 0.15s ease-in;
  position: relative;
  left: 0;
  vertical-align: -1px;
  margin-left: 8px;
  font-size: 13px;
}
.pin-link:hover {
  text-decoration: none;
}
.pin-link:hover:after {
  left: 4px;
}
.imageMapper--pinList:before {
  content: "";
  height: 2px;
  width: 35px;
  background: #31b7bc;
  display: block;
  margin-top: 1.5625rem;
}
.imageMapper--pinList .imageMapper--pinDetails .pinDetails--heading {
  font-weight: normal;
}
.imageMapper--pinList .imageMapper--pinDetails .pinDetails--preamble {
  font-size: 1rem;
}
.imageMapper--pinList .imageMapper--pinDetails .pinDetails--text {
  font-size: 15px;
}
.js-IMCanvas .pin .circle {
  opacity: 1 !important;
  -webkit-transition: 0.2s ease-in-out !important;
  transition: 0.2s ease-in-out !important;
  font-size: 15px !important;
  font-weight: bold;
}
.js-IMCanvas .pin .ring {
  display: none !important;
}
.js-IMCanvas.theme-midnight .pin .circle {
  background: #495a6b;
}
.js-IMCanvas.theme-midnight .pin .circle:hover {
  background: #879aad;
}
.js-IMCanvas.theme-ocean .pin .circle:hover {
  background: #CEEDEE;
  color: #595857;
}
.js-IMCanvas.theme-sun .pin .circle:hover {
  background: #FBE3C7;
  color: #595857;
}
.js-IMCanvas.theme-granite .pin .circle:hover {
  background: #D5D5D5;
  color: #595857;
}
.js-IMCanvas.theme-white .pin .circle {
  color: #18274a;
}
.js-IMCanvas.theme-white .pin .circle:hover {
  background: #CEEDEE;
  color: #595857;
}
.js-IMCanvas.theme-berry .pin .circle {
  background: #e56b78;
}
.js-IMCanvas.theme-berry .pin .circle:hover {
  background: #f8d7db;
  color: #595857;
}
.js-IMCanvas.theme-blue .pin .circle {
  background: #18274a;
}
.js-IMCanvas.theme-blue .pin .circle:hover {
  background-color: #495a6b;
  border-color: #495a6b;
}
.pin-details .div-link.wistia_embed {
  position: relative;
  font-size: 15px;
}
.pin-details .pin-heading {
  font-weight: normal !important;
}
.pin-details .wistia_click_to_play {
  background-color: transparent !important;
}
.ImageMapper-twocols .js-IMCanvas .pin-details {
  display: none !important;
}
.ImageMapper-twocols .pin-image,
.ImageMapper-twocols .pinDetails--image {
  margin-bottom: 1.5625rem;
  display: none !important;
}
@media only screen and (min-width: 57.5em), print {
  .ImageMapper-twocols .ImageMapper-heading.Heading--textCenter {
    text-align: left;
  }
}
@media only screen and (min-width: 48em), print {
  .js-IMCanvas .imageMapper--pinList {
    display: block;
  }
}
@media only screen and (min-width: 57.5em), print {
  .js-IMCanvas .imageMapper--pinList {
    display: none;
  }
}
.ImageMapper--snapshot .imageMapper--pinList {
  display: block !important;
}
.ImageMapper .Slider {
  margin-top: 0;
  margin-bottom: 0;
}
.Ingress {
  position: relative;
  overflow: hidden;
}
.Ingress-heading {
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 57.5em), print {
  .Ingress-heading {
    position: relative;
    left: -3.75rem;
    z-index: 9;
  }
}
.Ingress-contentAdjust {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}
.Ingress-content {
  padding: 1.5625rem;
}
@media only screen and (min-width: 57.5em), print {
  .Ingress-content {
    padding: 3.75rem 1.875rem 3.75rem 2.5rem;
    max-width: 580px;
  }
}
.Ingress-list {
  display: block;
  list-style: none;
  padding: 0;
}
.Ingress-listItem {
  display: block;
  margin: 0;
}
.Ingress-listItem:first-child .Ingress-listLink {
  border-top: 1px solid #ede9e9;
}
.Ingress-listLink {
  display: block;
  padding: 0.9375rem 6px;
  border-bottom: 1px solid #ede9e9;
  color: #18274a;
}
.Ingress-listLink:hover {
  text-decoration: none;
  color: #18274a;
  background: -webkit-gradient(linear, left top, right top, from(#ffffff), color-stop(20%, #fbfaf9), color-stop(80%, #fbfaf9), to(#ffffff));
  background: linear-gradient(90deg, #ffffff 0%, #fbfaf9 20%, #fbfaf9 80%, #ffffff 100%);
}
@media only screen and (min-width: 57.5em), print {
  .Ingress-listLink {
    font-size: 1.1875rem;
  }
}
.Ingress-listLink:after {
  font-weight: 700;
}
.Ingress-imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 141/164;
}
.Ingress-imageContent {
  max-width: 705px;
  position: relative;
}
.Ingress-imageShape {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url('../Images/Subtract-blob-crop.svg');
  position: absolute;
  z-index: 9;
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  right: -1px;
  height: 130%;
  top: -1px;
  max-width: 705px;
}
.Theme--themeSnow .Ingress-imageShape {
  background-image: url('../Images/Subtract-blob-crop-snow.svg');
}
.Theme--themeSnow .Ingress-listItem:first-child .Ingress-listLink {
  border-top: 1px solid rgba(24, 39, 74, 0.3);
}
.Theme--themeSnow .Ingress-listLink {
  border-bottom: 1px solid rgba(24, 39, 74, 0.3);
}
.Theme--themeSnow .Ingress-listLink:hover {
  background: -webkit-gradient(linear, left top, right top, from(#e9e4e3), color-stop(20%, #ede9e9), color-stop(80%, #ede9e9), to(#e9e4e3));
  background: linear-gradient(90deg, #e9e4e3 0%, #ede9e9 20%, #ede9e9 80%, #e9e4e3 100%);
}
.Ingress-image {
  display: none;
  overflow: hidden;
  background-size: cover;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}
.Ingress-image img {
  height: 100%;
}
.InsightColumn .Heading {
  margin: 0;
}
@media only screen and (min-width: 57.5em), print {
  .InsightColumn .Heading {
    font-size: 22px;
    line-height: 35px;
  }
}
.InsightColumn .Heading:after {
  content: "";
  display: block;
  height: 2px;
  width: 25px;
  background: #18274a;
  margin: 1.5625rem 0;
}
.InsightColumn p {
  margin-top: 0;
  font-size: 15px;
}
.InsightColumn .PublishDate {
  color: #595857;
  display: block;
  margin: 1.25rem 0 0.9375rem;
}
.InsightColumn .Column-image + .Column-text .Heading {
  padding-top: 0;
}
.InsightColumn-hero {
  border-bottom: 1px solid #ede9e9;
  padding: 24px 0;
  margin-bottom: 25px;
}
@media only screen and (min-width: 57.5em), print {
  .InsightColumn-hero {
    padding: 70px 0;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 57.5em), print {
  .InsightColumn-hero .Article-teaser {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.InsightColumn-hero .Heading {
  margin: 0;
}
@media only screen and (min-width: 57.5em), print {
  .InsightColumn-hero .Heading {
    font-size: 35px;
    line-height: 40px;
    font-weight: 100;
  }
}
.InsightColumn-hero .Heading:after {
  width: 35px;
}
@media only screen and (min-width: 57.5em), print {
  .InsightColumn-hero .Column-image {
    width: 50%;
    display: inline-block;
    padding-right: 15px;
  }
}
.InsightColumn-hero .Column-text {
  height: auto;
}
@media only screen and (min-width: 57.5em), print {
  .InsightColumn-hero .Column-text {
    width: 50%;
    display: inline-block;
    padding-left: 15px;
  }
}
.MediaBanner {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}
.MediaBanner .MediaBanner-content {
  padding-bottom: 1.875rem;
  padding-top: 1.875rem;
}
.MediaBanner .BlockMedia {
  width: 100%;
  padding: 0 0 1.875rem 0;
}
.MediaBanner .BlockMedia-image--main {
  display: none;
}
@media only screen and (min-width: 48em), print {
  .MediaBanner .BlockMedia-image--main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.MediaBanner .BlockMedia-image--device {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (min-width: 48em), print {
  .MediaBanner .BlockMedia-image--device {
    display: none;
  }
}
.MediaBanner.MediaBanner--bgCoverContent {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: auto;
  margin-right: auto;
}
.MediaBanner.MediaBanner--bgCoverContent .MediaBanner-content {
  max-width: 1170px;
}
.MediaBanner.MediaBanner--bgCoverContent .BlockMedia {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}
.MediaBanner-content {
  padding: 0;
  width: 100%;
}
@media only screen and (min-width: 57.5em), print {
  .MediaBanner-content {
    padding: 0 1.5625rem;
  }
}
.MediaBanner--bgCoverContent .MediaBanner-content {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 48em), print {
  .MediaBanner-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0 1.5625rem;
  }
}
.MediaBanner-content .Heading--textCenter {
  width: 100%;
  text-align: center;
}
/*
** Video /////////////////////////////////////////////
*/
.BlockMedia-video video {
  width: 100%;
  background-size: 100%;
  position: relative;
  /* Need to be in an after because of different browser implementation of flexbox items with padding */
}
.BlockMedia-video video:after {
  content: "";
  display: block;
  padding-bottom: 56.25%;
  height: 0;
}
/*
** Themes
*/
/* Function that is called from each theme */
.MediaBanner--themeSnow {
  background-color: #e9e4e3;
}
.MediaBanner--themeSnow .Heading {
  color: #18274a;
}
.MediaBanner--themeMidnight1 {
  background-color: #495a6b;
}
.MediaBanner--themeMidnight1 .Heading {
  color: #94b654;
}
.MediaBanner--themeMidnight2 {
  background-color: #495a6b;
}
.MediaBanner--themeMidnight2 .Heading {
  color: #fff;
}
.MediaBanner--themeBlue1 {
  background-color: #18274a;
}
.MediaBanner--themeBlue1 .Heading {
  color: #fff;
}
.MediaBanner--themeBlue2 {
  background-color: #18274a;
}
.MediaBanner--themeBlue2 .Heading {
  color: #f39200;
}
.MediaBanner--themeClay1 {
  background-color: #bfb1ac;
}
.MediaBanner--themeClay1 .Heading {
  color: #18274a;
}
.MediaBanner--themeClay2 {
  background-color: #bfb1ac;
}
.MediaBanner--themeClay2 .Heading {
  color: #fff;
}
/*.modularFinance:not(.ready) {
    color: transparent;
    animation-name: pulseBgColor;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in;
    background-color: @colorCoolGrey1;
} 
@keyframes pulseBgColor {
    to {
        background-color: @colorCoolGrey4;
    }
}
*/
.mce-content-body {
  /*    .modularFinance:not(.ready) {
        animation: none;
        color: unset;
    }*/
}
.mce-content-body .modularFinance {
  vertical-align: middle;
  padding-left: 22px;
  background: #f5f5f5 3px 2px no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAANCAMAAABBwMRzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpQTFRFfdT1etP1ddL1gNb1ftX1dtL2UVllbND1gNX1edP1gdX2ftX2gdb1cND1fNX1o+D1ouD1ddP1etT2dtP2gNX2dtP1bM/1ot/0edT1bc/2fdX1bND2gdX1dtL1eNP1dNL1fdT2bM71gdb2ftT2gNb2cdH2etT1ot/1cNH1UlllUlpmUlpl9fX1AAAAf7c0zQAAAI1JREFUeNpkjkcWg0AMQ02HhE56I40Zxo7uf7142OVFsrSwF/7knHg5Tl2aCpPogv0wC4ujz68IF2wQYYXn0tS8ba6O9rkt27IlvO4xrU+xFtU1yJoiKPpD0N+MMYElDGE4Jtvk2nXhkOh9nudp0lSVdkPYIcNRk+GBM/7+O/FWSqeEzMSec0H11PIVYADYUBjazT4L0gAAAABJRU5ErkJggg==");
  margin: 1px 5px;
  border: 1px solid #aaadb2;
  font-family: Inter, sans-serif;
  font-size: 13px;
  line-height: 18px;
}
.mce-content-body .modularFinance[data-mce-selected] {
  outline: 2px solid #2276d2;
}
/*#

Full article view.

*/
@media only screen and (min-width: 48em), print {
  .NewsArticle,
  .NewsArticle-share {
    width: 80%;
    margin: auto;
  }
}
@media only screen and (min-width: 57.5em), print {
  .NewsArticle,
  .NewsArticle-share {
    width: 70%;
  }
}
@media only screen and (min-width: 64em) {
  .NewsArticle,
  .NewsArticle-share {
    width: 60%;
  }
}
.NewsArticle {
  overflow-x: auto;
}
.NewsArticle h2,
.NewsArticle h3,
.NewsArticle h4,
.NewsArticle h5,
.NewsArticle h6 {
  color: #18274a;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: -0.02em;
  font-weight: 400;
}
.NewsArticle img {
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 1.5625rem;
}
.NewsArticle .List-main {
  vertical-align: middle;
}
.NewsArticle .List--itemList .List-itemHeading i {
  margin-right: 6px;
}
.NewsArticle ol,
.NewsArticle ul {
  padding-left: 1rem;
}
.NewsArticle ol li,
.NewsArticle ul li {
  margin-bottom: 0.5rem;
}
.NewsArticle ol li > span,
.NewsArticle ul li > span {
  padding-left: 10px;
  display: block;
}
.NewsArticle ol li ol,
.NewsArticle ul li ol,
.NewsArticle ol li ul,
.NewsArticle ul li ul {
  padding-left: 1.6rem;
  margin: 12px 0;
}
.NewsArticle-heading {
  text-align: center;
  font-size: 1.25rem;
}
@media only screen and (min-width: 48em), print {
  .NewsArticle-heading {
    font-size: 2.5rem;
  }
}
.NewsArticle-metaData {
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 3em;
  margin-top: 1em;
}
@media only screen and (min-width: 48em), print {
  .NewsArticle-metaData {
    margin-bottom: 5em;
    margin-top: 2em;
  }
}
.NewsListBlock .TinySlider-header {
  height: 45px!important;
  top: -10px;
}
.Theme--themeSnow .NewsListBlock-wrapper {
  padding: 30px 0;
}
@media only screen and (min-width: 57.5em), print {
  .Theme--themeSnow .NewsListBlock-wrapper {
    padding: 70px 0;
  }
}
.NewsListBlock-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 3.75rem;
}
.NewsListBlock-sorting .Select {
  margin-right: 1.25rem;
}
.NewsListBlock-sorting select {
  min-width: 150px;
  padding: 15px;
}
.NewsListBlock-toggleView {
  min-width: 80px;
  height: 40px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAoCAYAAABpYH0BAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKZSURBVHgB7ZtPaxNBGMafmY3GVgt7Umr9syJoDwoJiCWIJVIQ9CA9ee3m6qUI6r2fwQ+Q7TfoSQ9Suuoh6MEIXurJtFqDnupFIjU7nWnppU2zM5kddhbmB8uG8L675OHd93l3Nkv8oO6PlceaAOp882EJj+7X8OzJY0ycGYeNEILo707/KTk//bDJGAthITeng87yyxdLsA1GA0rJIgPikq3iCb6sd/zJS1ciWEh3YwNCRAq7saalHIEkHV6Bvu0CWo8TUBMnoCZOQE2cgJqUZIJ+rr9Kjbk9F+LH1u+9zxenzuHDajM1h8+g0OV9q12nHlmAAZI+W75bq8bDYqQEtBk+SgRgJIQBKNjbtBglAQdVzMfVCBemzg6M/771CzNzjSPfy1S0LCf43cBOQhowgDh2WkzhK7BWq3b4LkJOOBPRxAmoSeEv4bVWO/Dg1WGAPvrxvf0WcSxKAg4aTY4zEIHsOKODx9cxPcqMnMRLIMwpGhajJKAQRJVRclQgQAeERTBAIo6ddv7J6w9YWtCwKjvgYIjWyRlE9+trAgvpbn4LCfGaUhUo80OzyCkizoU1kapAmT4m7jp0c0Zhrd32Sz1UYID/vAdm4sIyTprXYoLXwzyl1IgLn0ySbF14UF9LM4tRcpSg2GaExTAAk3BhJQFFlR0m78WE2ZnqCt+tICeciWjiBNTECahJ4RcT3rXaoWfIhcFd+E6tGg0LURJQtfmLcSZLw7CRwlfg7H6FRMgJKQFVB14xvmQxJBcBZyKaOAE1cQJq4gTUxAmoiRBwGw51GA3ETowxMd/mYSGVG1c/f3qzGcIyKCGXuYKL/IlWVCr/Kzd6p3p/CDPzD6dRmDg9jluVa1h6vlAhBGafi44EE1dtLF5z2AU+58gb99T77AAAAABJRU5ErkJggg==');
}
.NewsListBlock-toggleView span {
  display: inline-block;
  width: 38px;
  height: 40px;
}
.NewsListBlock-toggleView span:hover {
  cursor: pointer;
}
.NewsListBlock-toggleView.js-list {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAoCAYAAABpYH0BAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKHSURBVHgB7Zu9axRBGMaf2STgB8p1EkSzwUIURU2hnDYWsUhnGi2D/gHG2FmpVUDxq7JMLHPNCVZBhTSXjUGiZ0RBkNu7y6HXLSZe0GLHmZA0yWV39t4dbhbmB8cdxzzNw7vzvPPeDatUKrl9vT1TAC5zIAfDWF1r4d7Dl/hQ/o7VPy2YAuNsuvWvNcGaK7UpcFwNwZ+Dhz4M4+bE4/FPX36chZm8Yr/qNc5D/qB/YOA+DKT/+AiHuQTOxhtjPiydkHNgIWENJGINJGINJGINJNKrsqj0vhzbSuzt63OHhk5W5WfP++iGjlOJ01y6cIaBSP78aVwbvQIdFIpv4C0uR65RMtBkjh4+hOujw9CBt/gZHlI0sF3FlBbKFTC4u0h8oRncoVGoaFXmRYXcvvsEOpiPqT5J5iuw3miiXmyiW9gQIWINJJL5R/iICJGLIol1UBJ74EojentIZKDY/Nu1Jm6ExN1FkxrSvKeTd6ADGU6FYooGItqsNDXK1ESFFIpvoYN6Iz6clAx0wnAwbs1WEy3J58/5opmO1aSBbHQ9hXZDF0oGSkOQkE40WcSmMBGlCpRn27g12yuuE00nHDywH6dOHIMO5P6aSgqrDAaWlr4mHiYIyMOEkeF8plLYb/Odi/Q1ygS/17SFSGopvEXbwUCXhwmz7xY2Xt3ChggRayARayCRzA8T5DRabwpHHxOTDhOSbv5umoFhIpmvwBlRITOahgkqKBmY9NezzRMGuUnOAjZEiFgDiVgDiVgDiVgDiTgiKgNw7sJcAhiMbGPmHIeN/6xWjfyr79ityWD5m2/c7QEJ53yayWsOe3qcZ4yxMRiIvObw6EUBr2c9GIR8KubW/67f+A/XFuuUuAtjsgAAAABJRU5ErkJggg==');
}
.NewsListBlock-listView.js-grid .js-listItem {
  display: none;
}
.NewsListBlock-listView.js-grid .js-gridItem {
  display: block;
}
.NewsListBlock-listView.js-list .js-listItem {
  display: block;
}
.NewsListBlock-listView.js-list .js-gridItem {
  display: none;
}
.NewsListBlock-listViewItem {
  margin-bottom: 0;
}
.NewsListBlock-listViewItem span {
  color: #18274a;
}
.NewsListBlock-listViewItem a {
  display: block;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 0.9375rem;
  margin-top: 0.9375rem;
}
.NewsListBlock-gridViewItem {
  /* 1, blue1 */
  /* 2, cliff */
  /* 3, midnight2 */
}
.NewsListBlock-gridViewItem:hover {
  color: #e9e4e3;
}
.NewsListBlock-gridViewItem:hover .Heading {
  color: #e9e4e3;
}
.NewsListBlock-gridViewItem .ColorTeaser {
  color: #fff;
}
.NewsListBlock-gridViewItem .ColorTeaser .Heading {
  color: #fff;
}
.NewsListBlock-gridViewItem:nth-child(3n+1) .ColorTeaser {
  background: #18274a;
}
.NewsListBlock-gridViewItem:nth-child(3n+2) .ColorTeaser {
  background: #e9e4e3;
  color: #595857;
}
.NewsListBlock-gridViewItem:nth-child(3n+2) .ColorTeaser .ColorTeaser-heading {
  color: #595857;
}
.NewsListBlock-gridViewItem:nth-child(3n+3) .ColorTeaser {
  background: #495a6b;
}
/* new cards dont have the extra list view block so we dont have to skip every other */
.News-card .NewsListBlock-gridViewItem {
  /* 1, blue1 */
  /* 2, cliff */
  /* 3, midnight2 */
}
.News-card .NewsListBlock-gridViewItem:nth-child(3n+1) .ColorTeaser {
  background: #18274a;
}
.News-card .NewsListBlock-gridViewItem:nth-child(3n+2) .ColorTeaser {
  background: #e9e4e3;
  color: #595857;
}
.News-card .NewsListBlock-gridViewItem:nth-child(3n+2) .ColorTeaser .ColorTeaser-heading {
  color: #595857;
}
.News-card .NewsListBlock-gridViewItem:nth-child(3n+3) .ColorTeaser {
  background: #495a6b;
}
.FilterBtn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.Filter-btn {
  aspect-ratio: 1;
  border: 1px solid #e9e4e3;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 100%;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  display: none;
  background: transparent;
}
.Filter-btn.js-FilterBtn-trigger--close {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.Filter-btn:hover {
  background: #ede9e9;
}
.PageFilter {
  border-top: 1px solid #ede9e9;
  margin-bottom: 3.75rem;
  padding-top: 3.75rem;
  min-height: 30px;
  padding-bottom: 1.875rem;
}
.PageFilter .Button {
  margin: 0 12px 12px 0;
  padding: 0 20px;
  border-width: 1px;
  min-height: 30px;
  height: 30px;
}
.PageFilter--noBorder {
  border: none;
}
.PageFilter--narrow {
  padding-top: 32px;
  padding-bottom: 32px;
}
.PageFilter-toggle {
  -webkit-transition: max-height ease-in-out 0.4s;
  transition: max-height ease-in-out 0.4s;
  max-height: 0;
  overflow: hidden;
}
.PageFilter-toggle.is-open {
  max-height: 1000px;
  overflow: visible;
}
.PageFilter-clear {
  color: #e56b78;
  cursor: pointer;
  font-size: 15px;
  display: inline-block;
}
.PageFilter-clear i {
  font-size: 12px;
}
.PageFilter-clear:hover {
  color: #18274a;
}
.PageFilter-clear--inline {
  margin-left: 40px;
}
.PageFilter .PageFilter-clear--inline {
  margin-left: 10px;
}
.PageNav-language {
  display: none;
}
.PageNav-language.is-active {
  display: inline;
  position: absolute;
  top: 85px;
  right: -18px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.PageNav-item:hover {
  color: #fff;
}
.PageNav-item:hover .PageNav-sub {
  display: block;
}
.PageNav-item--tool {
  margin-right: 0;
}
.PageNav-sub {
  z-index: 1;
  left: -40px;
}
.Header-tertiaryNav .PageNav-list .PageNav-item .PageNav-sub {
  left: unset;
  right: -20px;
}
.Header-tertiaryNav .PageNav-item.RegionSelect {
  /*
        .Select {
            &.Select--dropdown {
                background: transparent;
                border: 0;
                display: flex;
                appearance: none;
                position: relative;

                &:before {
                    font-family: 'Getinge-icon' !important;
                    content: "\e900";
                    padding: 2px 16px;
                }

                .Select-input {
                    color: @colorWhite;
                    text-align: right;

                    > option {
                        text-align: right;
                    }

                    optgroup {
                        color: #000;
                        text-align: left;
                    }
                }

                &:after {
                    font-family: Getinge-icon;
                    content: "\e906";
                    font-size: 6px;
                    position: relative;
                    margin-left: -10px;
                    top: -2px;
                    background-color: transparent;
                    background-image: none;
                }
            }
        }*/
}
.Header-tertiaryNav .PageNav-item.RegionSelect .PageNav-sub {
  line-height: 20px;
}
.Header-tertiaryNav .PageNav-item.RegionSelect .PageNav-sub:before {
  height: 29px;
}
.PageNav-secondSub {
  list-style: none;
  margin: 0 14px;
  position: relative;
  color: #63656a;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  padding: 0;
  margin: 0;
}
.PageNav-secondSub .PageNav-secondSubItem,
.PageNav-secondSub .PageNav-secondSubItem:first-child,
.PageNav-secondSub .PageNav-secondSubItem:last-child {
  padding: 8px 0;
  border: none;
}
.PageNav-secondSub .PageNav-secondSubItem .is-active {
  text-decoration: underline;
}
.PageNav-subItemHeader {
  color: #beb0ac;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
}
.PageTeaserListing .Grid-cell .Banner {
  border-top: 1px solid #ede9e9;
  border-bottom: 1px solid #ede9e9;
  margin-top: 70px;
}
.ProductPage-relatedProducts .Grid-cell:nth-child(n+4) {
  display: none;
}
@media only screen and (min-width: 57.5em), print {
  .ProductPage-relatedProducts .Grid-cell:nth-child(n+4) {
    display: block;
  }
  .ProductPage-relatedProducts .Grid-cell:nth-child(n+5) {
    display: none;
  }
}
.ProductPage .Tabs-header {
  list-style: none;
}
.ProductPage .Tabs-item:nth-last-child(2)::after {
  content: "";
}
.ProductHero {
  background: #fbfaf9;
  margin-bottom: 1.5625rem;
}
@media only screen and (min-width: 57.5em), print {
  .ProductHero {
    margin-bottom: 2.5rem;
  }
}
.ProductHero .Grid {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
@media only screen and (min-width: 57.5em), print {
  .ProductHero .Grid {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.ProductHero .wistia_responsive_wrapper {
  height: 100%;
}
.ProductHero-left {
  /* padding-right: @spaceMedium;
    padding-left: @spaceMedium;*/
}
@media only screen and (min-width: 57.5em), print {
  .ProductHero-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding-right: 1.5625rem;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
}
@media only screen and (min-width: 57.5em), print {
  .ProductHero-linkWrapper {
    max-width: 585px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    position: relative;
    width: 100%;
    min-height: 70px;
  }
}
.ProductHero-content {
  padding-right: 1.5625rem;
  padding-left: 1.5625rem;
  padding-top: 1.5625rem;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 57.5em), print {
  .ProductHero-content {
    padding-top: 0;
    max-width: 585px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
  }
}
.ProductHero-content .CallToAction {
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 57.5em), print {
  .ProductHero-content .CallToAction {
    margin-bottom: 40px;
    border: none;
    text-align: left;
  }
}
.ProductHero-linkBack {
  display: none;
}
@media only screen and (min-width: 57.5em), print {
  .ProductHero-linkBack {
    display: block;
    position: absolute;
    left: 1.5625rem;
    top: 1.5625rem;
    font-size: 13px;
  }
  .ProductHero-linkBack:before {
    font-size: 13px;
  }
}
.ProductHero-btn {
  margin-top: 40px;
  border-top: 1px solid #ede9e9;
  width: 100%;
}
@media only screen and (min-width: 57.5em), print {
  .ProductHero-btn {
    margin: 0;
    border: none;
    text-align: left;
  }
}
.ProductHero-btn .CallToAction {
  padding-top: 0;
}
.ProductHero-btn .CallToAction .Link {
  display: block;
  margin-top: 5px;
  font-size: 15px;
}
.ProductList-item {
  display: block;
  width: 100%;
}
.ProductList-item:hover {
  text-decoration: none;
}
.ProductList-item:hover .Heading {
  color: #66bbb0;
}
.ProductList-object {
  background: #fbfaf9;
  padding: 1.25rem;
  text-align: center;
  margin-bottom: 0.9375rem;
}
.ProductList-image {
  background-blend-mode: multiply;
  background-size: cover;
  width: 100%;
  padding-top: 100%;
  background-color: #fbfaf9;
  background-position: center;
}
.ProductList-Heading {
  color: #18274a;
  font-size: 15px;
  line-height: 25px;
}
.ProductList-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #495a6b;
  font-size: 13px;
  margin: 0 auto;
}
.ProductList--bgColor .ProductList-object {
  background: transparent;
  padding: 0;
}
.ProductList--bgColor .ProductList-image {
  background-blend-mode: unset;
  background-size: cover;
  width: 100%;
  padding-top: 0;
  background-color: #fbfaf9;
  aspect-ratio: 1;
}
.ProductList--bgColor .ProductList-Heading {
  font-weight: 500;
}
.ProductList .Filter-header .Heading {
  text-align: left;
}
.ProductList .Filter-header .Heading.ProductList-Heading--textCenter {
  text-align: center;
}
.Search h1:after {
  content: "";
  display: block;
  height: 2px;
  width: 35px;
  background: #000;
  margin: 1.5625rem 0 1.25rem;
}
.Search form {
  position: relative;
}
.Search .icon-Search {
  position: relative;
  top: 43px;
  left: 20px;
}
.Search input {
  background-color: #fff;
  border: 1px solid #18274a;
  padding-left: 50px!important;
}
.Search .Search-inputHelperText {
  display: none;
}
@media only screen and (min-width: 57.5em), print {
  .Search input:focus + .Search-inputHelperText {
    display: block;
    position: absolute;
    top: 41px;
    right: 20px;
    color: #d4cac8;
  }
}
.Search .Search-spinner {
  display: none;
  position: absolute;
  top: 28px;
  right: 1px;
  width: 55px;
  height: 55px;
}
.Search .Search-spinner img {
  width: 55px;
  height: 55px;
}
.Search .Searchbox {
  position: relative;
}
.Search .Searchbox input {
  padding-left: 50px !important;
}
.Search .Searchbox .Search-spinner {
  display: block;
}
.Search .Searchbox-rounded input {
  border: 1px solid #18274a;
  border-radius: 30px;
  height: 40px;
}
.Search .Searchbox-rounded .Search-spinner {
  top: 19px;
}
.Search .Searchbox-rounded .icon-Search {
  top: 35px;
}
@media only screen and (min-width: 57.5em), print {
  .Search.is-loading .Search-spinner {
    display: block;
  }
}
.Search .Search-autoComplete {
  position: relative;
  z-index: 10;
}
.Search .Search-autoComplete .Search-results-popup {
  top: 0;
}
.Search-results .Button--bordered {
  min-width: 0;
}
.Search-results .Button--bordered.is-active {
  background-color: #18274a !important;
  border-color: #18274a !important;
  color: #fff !important;
}
.Search-results .Button--bordered span {
  color: #e56b78;
  margin-left: 5px;
}
.Search-results .BlockMargin {
  margin-top: 0;
}
.Search-results .SearchButton {
  display: none;
}
.Search-results .SearchButton.is-visible {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.Search-results-popup {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 74px;
  left: 0;
  background: #fff;
  -webkit-box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.Search-results-popup ul,
.Search-results-popup li {
  list-style: none;
  padding: 4px 16px;
  margin: 0;
}
.Search-results-popup li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e9e4e3;
}
.Search-results-popup li a {
  font-size: 15px;
  color: #18274a;
}
.Search-results-popup li span {
  font-size: 13px;
  font-weight: 300;
  color: #bfb1ac;
}
.Search-results-popup li:last-child {
  border-bottom: none;
}
.Search-resultsItem {
  border: none;
  padding-bottom: 0.9375rem;
}
.Search-resultsItem .Search-resultsItemCta {
  border-top: 1px solid #bfb1ac;
}
.Page-container.Page-container--narrow {
  max-width: 770px;
}
.Quicksearch .Quicksearch-content {
  padding: 0 1.5625rem;
  max-width: 48rem;
  margin: 0 auto;
}
.Quicksearch img.loader {
  display: none;
  position: absolute;
  top: -3px;
  right: -4px;
  z-index: 3;
  width: 45px;
}
.Quicksearch form {
  position: relative;
}
.Quicksearch form.is-loading img.loader {
  display: block;
}
.Quicksearch form.is-loading input[type=text] {
  pointer-events: none;
  color: #bfb1ac;
  cursor: not-allowed;
  opacity: 0.8;
}
.Quicksearch label.FloatedLabel-label {
  border: 0 !important;
  margin: 0 30px;
  padding: 0 4px;
}
.Quicksearch input[type=text] {
  border: 1px solid #18274a;
  border-radius: 30px;
  height: 40px;
  z-index: 2;
  padding-left: 50px;
}
.Quicksearch button[type=submit].icon-Search {
  color: #595857;
  background-color: transparent;
  z-index: 3;
  border: 0;
  padding: 0;
  min-width: 20px;
  position: absolute;
  top: 14px;
  left: 20px;
}
.CallToAction .Quicksearch.Page-container {
  padding: 0 0.625rem;
}
.Section-paddingLarge,
.Section {
  padding: 2.5rem 0;
}
@media only screen and (min-width: 48em), print {
  .Section-paddingLarge,
  .Section {
    padding: 3.75rem 0;
  }
}
@media only screen and (min-width: 64em) {
  .Section-paddingLarge,
  .Section {
    padding: 4.125rem 0;
  }
}
@media only screen and (min-width: 73.125em) {
  .Section-paddingLarge,
  .Section {
    padding: 5.25rem 0;
  }
}
.Section--themeSnow,
.Section.Theme--themeSnow {
  background: #e9e4e3;
}
.Section--break {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}
.Section-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2.5rem;
}
.Section-heading {
  margin-bottom: 0;
}
/*#

Allows to create a slider with any type of content.

<div class="Slider owl-carousel owl-theme" data-module="slider" data-slider='{"autoPlay": true, "autoplayTimeout": 7000}'>
    <div class="Slider-item"><h2 style="height: 150px; background: #eee;margin:0;padding: 1em 2em 2em;">Slide 1</h2></div>
    <div class="Slider-item"><h2 style="height: 150px; background: #ddd;margin:0;padding: 1em 2em 2em;">Slide 2</h2></div>
    <div class="Slider-item"><h2 style="height: 150px; background: #ccc;margin:0;padding: 1em 2em 2em;">Slide 3</h2></div>
</div>

*/
.Slider {
  padding-bottom: 50px;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
@media only screen and (min-width: 48em), print {
  .Slider {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.Slider .Slider-previousSlide,
.Slider .Slider-nextSlide {
  display: block;
}
@media only screen and (min-width: 80em) {
  .Slider .Slider-previousSlide,
  .Slider .Slider-nextSlide {
    display: block;
  }
}
@media only screen and (min-width: 80em) {
  .Slider:hover .Slider-previousSlide,
  .Slider:hover .Slider-nextSlide {
    opacity: 1;
  }
}
.Slider .Banner {
  margin: 0 !important;
}
.Slider-previousSlide,
.Slider-nextSlide {
  opacity: 0;
  cursor: pointer;
  height: 48px;
  width: 48px;
  text-indent: 48px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  margin-top: -98px / 2;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDggNDgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4IDQ4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6IzAwMjg1Njt9PC9zdHlsZT48ZyBpZD0iWE1MSURfNDJfIj48cG9seWdvbiBpZD0iWE1MSURfNDNfIiBjbGFzcz0ic3QwIiBwb2ludHM9IjMyLjksNDQuMiAxMSwyMi41IDMzLDIuNSAzNSw0LjcgMTUuNSwyMi42IDM1LjEsNDIgIi8+PC9nPjwvc3ZnPg==);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 48px;
}
.Slider-previousSlide {
  left: 0.5em;
}
.Slider-nextSlide {
  right: 0.5em;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  -ms-transform: rotate(180deg);
}
@media only screen and (min-width: 48em), print {
  .Slider--paginationOverlay {
    padding-bottom: 0;
  }
  .Slider--paginationOverlay.owl-theme .owl-controls {
    bottom: 0;
  }
}
.SlideScroll {
  height: 100%;
  /** Chrome Scroll Wheel **/
}
.SlideScroll .SlideScroll-wrapper {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  will-change: transform;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  height: 100%;
}
.SlideScroll .SlideScroll-wrapper img,
.SlideScroll .SlideScroll-wrapper .Wistia-video {
  width: auto;
  max-height: 300px;
}
@media only screen and (min-width: 57.5em), print {
  .SlideScroll .SlideScroll-wrapper img,
  .SlideScroll .SlideScroll-wrapper .Wistia-video {
    max-height: 430px;
  }
}
@media only screen and (min-width: 73.125em) {
  .SlideScroll .SlideScroll-wrapper img,
  .SlideScroll .SlideScroll-wrapper .Wistia-video {
    max-height: 640px;
  }
}
.SlideScroll .SlideScroll-wrapper .Wistia-video {
  vertical-align: middle;
  height: 100%;
  display: inline-block;
  min-height: 300px;
}
@media only screen and (min-width: 57.5em), print {
  .SlideScroll .SlideScroll-wrapper .Wistia-video {
    min-height: 430px;
  }
}
@media only screen and (min-width: 73.125em) {
  .SlideScroll .SlideScroll-wrapper .Wistia-video {
    min-height: 640px;
  }
}
.SlideScroll .SlideScroll-item.active {
  background: rgba(255, 255, 255, 0.3);
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
.SlideScroll .SlideScroll-item {
  display: inline-block;
  min-height: 150px;
  min-width: 100px;
  margin: 0 -2px;
}
@media screen and (max-width: 500px) {
  .SlideScroll .SlideScroll-item {
    /*min-height: 200px;
            min-width: 200px;*/
  }
}
.SlideScroll ::-webkit-scrollbar {
  height: 12px;
  position: relative;
}
.SlideScroll ::-webkit-scrollbar-track {
  background: #fff !important;
  border: 1px solid #ede9e9;
  padding: 2px 0;
}
.SlideScroll ::-webkit-scrollbar-thumb {
  background: #495a6b !important;
  border-radius: 16px;
}
.SlideScroll ::-webkit-scrollbar-thumb:window-inactive {
  background: #495a6b !important;
}
@supports (scrollbar-color: auto) {
  .SlideScroll {
    scrollbar-color: #495a6b #fff;
  }
}
@supports selector(::-webkit-scrollbar) {
  .SlideScroll {
    scrollbar-color: auto;
  }
}
.SplitContent {
  padding: 30px 0 0px;
  border-bottom: 1px solid #ede9e9;
}
.SplitContent p {
  margin-top: 0;
}
.SplitContent--clean {
  border-bottom: none;
}
@media only screen and (min-width: 48em), print {
  .SplitContent {
    padding: 0 0 40px;
  }
}
.SplitContent.BlockMargin.BlockMargin--large {
  margin-top: 10px;
}
@media only screen and (min-width: 48em), print {
  .SplitContent.BlockMargin.BlockMargin--large {
    margin-top: 40px;
  }
}
@media only screen and (min-width: 57.5em), print {
  .SplitContent.BlockMargin.BlockMargin--large {
    margin-top: 70px;
  }
}
.SplitContent .CallToAction ul {
  padding: 0;
  margin-bottom: 20px;
}
.SplitContent .CallToAction ul li {
  list-style: none;
}
@media only screen and (min-width: 57.5em), print {
  .SplitContent-left {
    width: 50%!important;
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
  }
}
@media only screen and (min-width: 57.5em), print {
  .SplitContent-right {
    width: 50% !important;
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
  }
}
.SplitContent-preamble:after {
  content: "";
  display: block;
  height: 4px;
  width: 40px;
  background: #ede9e9;
  margin-bottom: 1.875rem;
}
@media only screen and (min-width: 48em), print {
  .SplitContent-preamble:after {
    margin-bottom: 2.5rem;
  }
}
@media only screen and (min-width: 57.5em), print {
  .SplitContent-preamble:after {
    display: none;
  }
}
@media only screen and (min-width: 48em), print {
  .SplitContent-preamble {
    padding-right: 1rem;
  }
}
.SplitContent-preamble .SplitContent-heading {
  margin-bottom: 1.875rem;
}
@media only screen and (min-width: 48em), print {
  .SplitContent-preamble .SplitContent-heading {
    margin-bottom: 2.5rem;
  }
}
@media only screen and (min-width: 57.5em), print {
  .SplitContent-preamble .SplitContent-heading {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 57.5em), print {
  .SplitContent-content {
    padding-left: 1rem;
  }
}
.SplitContent-content p,
.SplitContent-content .CallToAction,
.SplitContent-content .Wistia-video.SplitContent-media {
  margin-bottom: 30px;
}
@media only screen and (min-width: 48em), print {
  .SplitContent-content p,
  .SplitContent-content .CallToAction,
  .SplitContent-content .Wistia-video.SplitContent-media {
    margin-bottom: 40px;
  }
}
.SplitContent-content .CallToAction {
  max-width: 100%;
}
.SplitContent-content .Wistia-video.SplitContent-media {
  position: relative;
}
.SplitContent-content .Wistia-video.SplitContent-media .wistia_embed {
  display: block !important;
  position: absolute !important;
}
.SplitContent-content .Wistia-video.SplitContent-media .wistia_click_to_play {
  height: 100% !important;
}
.SplitContent-content .Wistia-video.SplitContent-media .wistia_click_to_play > div {
  height: 100% !important;
}
.SplitContent-content .Wistia-video.SplitContent-media .wistia_click_to_play > div img {
  height: 100% !important;
  position: relative !important;
}
.SplitContent-content .Wistia-video.SplitContent-media:after {
  content: "";
  display: block;
  padding-bottom: 56.25%;
  height: 0;
}
.SplitContent-content img {
  height: auto;
  width: auto;
  max-width: 100%;
  margin-bottom: 30px;
}
@media only screen and (min-width: 48em), print {
  .SplitContent-content img {
    margin-bottom: 40px;
  }
}
.SplitContent-content p img {
  margin-bottom: 0;
}
.SplitContent-content h3 img {
  margin-bottom: 0;
}
.SplitContent-content img[style*="float: left"] {
  max-width: 40%;
  height: auto;
  width: auto;
  margin-right: 1.25rem;
  margin-bottom: 0;
}
@media only screen and (min-width: 48em), print {
  .SplitContent-content img[style*="float: left"] {
    margin-right: 0.9375rem;
    max-width: 100%;
  }
}
.SplitContent-content ul {
  margin-top: 0;
  margin-bottom: 30px;
  padding-left: 1rem;
}
@media only screen and (min-width: 48em), print {
  .SplitContent-content ul {
    margin-bottom: 40px;
  }
}
.SplitContent-content ol {
  padding-left: 1rem;
  margin-top: 0;
  margin-bottom: 30px;
}
@media only screen and (min-width: 48em), print {
  .SplitContent-content ol {
    margin-bottom: 40px;
  }
}
.SplitContent-content p,
.SplitContent-content li,
.SplitContent-content span,
.SplitContent-content a {
  font-size: 1rem;
  line-height: 26px;
}
.SplitContent-content .Preamble,
.SplitContent-content .Lead {
  font-size: 1.1875rem;
  line-height: 30px;
}
.SplitContent-content .Button {
  font-size: 15px;
}
.SplitContent-content Table a {
  font-size: 1rem;
  text-decoration: underline;
}
.SplitContent-content Table img {
  margin: 0 auto;
}
.SplitContent-content Table img[style*="float: left"] {
  max-width: 100%;
  margin-right: 0;
}
img.SplitContent-media {
  margin-bottom: 30px;
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 48em), print {
  img.SplitContent-media {
    margin-bottom: 40px;
  }
}
.Tabs-panel .Banner-content {
  padding: 0 !important;
}
.SplitHeader {
  padding: 3.75rem 0;
}
@media only screen and (min-width: 57.5em), print {
  .SplitHeader {
    padding: 5.625rem 0 3.75rem;
  }
}
.Status--referenceOverlay {
  border-bottom: 1px solid #fff;
}
.Sticky-element.is-sticky {
  -webkit-transition: top 370ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: top 370ms cubic-bezier(0.86, 0, 0.07, 1);
}
.StickyHeader {
  height: 146px;
}
.StickyHeader--sticky {
  position: fixed !important;
  visibility: hidden;
  top: -60px;
}
@media only screen and (min-width: 57.5em), print {
  .StickyHeader--sticky {
    top: -146px;
  }
}
.StickyHeader--proportions {
  min-width: 100%;
  border-bottom: 1px solid rgba(237, 233, 233, 0);
  background-color: rgba(24, 39, 74, 0);
}
.StickyHeader--proportions > .Header-navigation {
  max-width: 1220px;
}
.StickyHeader--proportions .Header-secondaryNav .PageNav-list .PageNav-item.is-active,
.StickyHeader--proportions .Header-nav .PageNav-list .PageNav-item.is-active {
  border: none;
}
.StickyHeader--proportions > .Breadcrumb {
  display: none;
}
.StickyHeader--background {
  background-color: #18274a;
}
.StickyHeader--inverted {
  background-color: #fff !important;
  color: #18274a;
}
.StickyHeader--animated {
  -webkit-transition: all 370ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 370ms cubic-bezier(0.86, 0, 0.07, 1);
}
.StickyHeader--show {
  visibility: visible;
  top: 0;
  z-index: 101;
}
.StickyHeader--inverted.StickyHeader--animated {
  border-bottom: 1px solid #ede9e9;
}
.StickyMenu-offset {
  top: 60px!important;
  z-index: 9!important;
}
@media only screen and (min-width: 57.5em), print {
  .StickyMenu-offset {
    top: 146px!important;
  }
}
.Tabs-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Tabs-list:after {
  display: none;
}
.Sticky-element.is-sticky .Tabs-heading {
  display: none;
}
@media only screen and (min-width: 57.5em), print {
  .Sticky-element.is-sticky .Tabs-heading {
    display: inline;
    -webkit-transition: 0.5s linear;
    transition: 0.5s linear;
    -webkit-transition-delay: 2s;
            transition-delay: 2s;
  }
}
.Tabs-header {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: right;
}
.Tabs-heading {
  display: none;
  font-size: 1.25em;
  line-height: 1.25em;
}
.Tabs-item .Heading {
  margin: 0;
}
.Tabs-item:last-of-type {
  padding-right: 0;
}
.Tabs-item:last-of-type:after {
  content: "";
  display: none;
}
.Tabs-horizontalScroll {
  max-width: 1170px;
  margin: 0 auto;
}
.Tabs-horizontalScroll .Tabs-scrollable {
  overflow-x: auto;
  scrollbar-width: none;
  padding-bottom: 1rem;
}
.Tabs-horizontalScroll .Tabs-scrollable::-webkit-scrollbar {
  display: none;
}
.Tabs-horizontalScroll .Tabs-scroll--left {
  background: -webkit-gradient(linear, right top, left top, color-stop(30%, #FFFFFF), color-stop(99.24%, rgba(255, 255, 255, 0)));
  background: linear-gradient(270deg, #FFFFFF 30%, rgba(255, 255, 255, 0) 99.24%);
}
.Tabs-horizontalScroll .Tabs-scroll--left span {
  margin-left: 60px;
}
.is-sticky .Tabs-horizontalScroll .Tabs-scroll--left {
  background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FFFFFF), color-stop(99.24%, rgba(255, 255, 255, 0)));
  background: linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 99.24%);
}
@media only screen and (min-width: 48em), print {
  .Tabs-horizontalScroll .Tabs-scroll--left {
    left: 0;
  }
}
.Tabs-horizontalScroll .Tabs-scroll--right {
  background: -webkit-gradient(linear, right top, left top, color-stop(30%, #FFFFFF), color-stop(99.24%, rgba(255, 255, 255, 0)));
  background: linear-gradient(270deg, #FFFFFF 30%, rgba(255, 255, 255, 0) 99.24%);
  left: calc(100% - 100px);
}
.Tabs-horizontalScroll .Tabs-scroll--right span {
  margin-right: -60px;
}
.is-sticky .Tabs-horizontalScroll .Tabs-scroll--right {
  background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FFFFFF), color-stop(99.24%, rgba(255, 255, 255, 0)));
  background: linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 99.24%);
}
@media only screen and (min-width: 48em), print {
  .Tabs-horizontalScroll .Tabs-scroll--right {
    left: calc(100% - 100px);
  }
}
.Tabs-horizontalScroll .Tabs-Scroll-Button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 54px;
  width: 100px;
  padding: 0;
  aspect-ratio: 1;
  top: 4px;
}
.Tabs-horizontalScroll .Tabs-Scroll-Button span {
  line-height: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.Tabs-horizontalScroll .Tabs-Scroll-Button span::before {
  font-size: 10px;
  color: #e56b78;
}
.Tabs .Tabs-scrollable {
  padding-bottom: 0 !important;
}
.Tabs .Tabs-scrollable ul.Tabs-list {
  overflow-x: scroll;
  padding-bottom: 0.9375rem;
  max-width: 100%;
}
@media only screen and (min-width: 34em), print {
  .Tabs .Tabs-scrollable ul.Tabs-list {
    overflow-x: unset;
    max-width: unset;
  }
}
.TeaserList .TeaserList-item {
  -webkit-transition: ease-in-out 0.4s;
  transition: ease-in-out 0.4s;
}
.TeaserList .TeaserList-item:hover {
  background: #e9e4e3;
}
.TeaserList-container p {
  color: #595857;
}
.TeaserList-headingWrapper {
  padding-left: 15px;
}
@media only screen and (min-width: 57.5em), print {
  .TeaserList-heading {
    font-size: 27px;
  }
}
.Teaser-content .Teaser-imageWrapper {
  margin-bottom: 0.625rem;
}
.Teaser-content p.u-textXSmall:first-of-type {
  margin-top: -10px;
}
.Teaser-imageWrapper {
  min-height: 150px;
  overflow: hidden;
  aspect-ratio: 2/1;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 100%;
}
.Teaser-imageWrapper--alternate {
  aspect-ratio: 16/9;
}
.Teaser-imageWrapper.Asset-video {
  -webkit-box-align: unset;
      -ms-flex-align: unset;
          align-items: unset;
}
.Teaser-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: -webkit-transform ease-in-out 0.4s;
  transition: -webkit-transform ease-in-out 0.4s;
  transition: transform ease-in-out 0.4s;
  transition: transform ease-in-out 0.4s, -webkit-transform ease-in-out 0.4s;
  height: 100%;
}
.Teaser-heading {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-transition: color ease-in-out 0.9s;
  transition: color ease-in-out 0.9s;
  line-clamp: 3;
  box-orient: vertical;
}
.Teaser-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.Teaser {
  position: relative;
  margin-bottom: 25px;
  width: 100%;
  display: block;
}
.Teaser:hover {
  text-decoration: none;
}
@media only screen and (min-width: 48em), print {
  .Teaser {
    margin-bottom: 0;
  }
}
.Teaser.Teaser--bgImage {
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  aspect-ratio: unset;
  padding: 0;
}
.Teaser.Teaser--bgImage .Teaser-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: absolute;
  padding: 1.5rem;
  background-color: rgba(24, 39, 74, 0.4);
  bottom: 0;
  width: 100%;
  min-height: 100%;
}
.Teaser.Teaser--bgImage .Teaser-content .Teaser-heading {
  line-height: 35px;
  color: #fff;
  margin: 0;
}
.TeaserCarousel .Teaser {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.TeaserCarousel .Teaser > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.TeaserCarousel .Teaser:hover .Heading {
  color: unset;
}
.TeaserCarousel .Teaser:hover .Teaser-imageWrapper > img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.TeaserCarousel .Teaser .Teaser-imageWrapper {
  aspect-ratio: 3/2;
  min-height: 150px;
  width: 100%;
}
.TeaserCarousel .Teaser .Teaser-imageWrapper > img {
  -webkit-transition: -webkit-transform ease-in-out 0.4s;
  transition: -webkit-transform ease-in-out 0.4s;
  transition: transform ease-in-out 0.4s;
  transition: transform ease-in-out 0.4s, -webkit-transform ease-in-out 0.4s;
}
.TeaserCarousel .Teaser .Teaser-content {
  padding: 1.5625rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media only screen and (min-width: 48em), print {
  .TeaserCarousel .Teaser .Teaser-content {
    padding: 1.875rem;
  }
}
.TeaserCarousel .Teaser .Teaser-content .Heading {
  margin-bottom: 25px;
}
.TeaserCarousel .Teaser .Teaser-content .Teaser-text {
  -webkit-line-clamp: unset;
}
.TeaserCarousel .Teaser .CallToAction {
  padding: 0 1.5625rem 1.5625rem 1.5625rem;
  margin: 0;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
}
@media only screen and (min-width: 48em), print {
  .TeaserCarousel .Teaser .CallToAction {
    padding: 0 1.875rem 1.875rem 1.875rem;
  }
}
.TeaserCarousel .Teaser .CallToAction .Link {
  margin: 0;
}
.TeaserCarousel.TinySlider .tns-outer {
  overflow: hidden;
}
@media only screen and (min-width: 57.5em), print {
  .TeaserCarousel.TinySlider .tns-outer {
    margin-left: 0;
  }
}
.TeaserCarousel.TinySlider .tns-inner {
  margin-left: 15px !important;
  margin-right: -15px;
}
@media only screen and (min-width: 57.5em), print {
  .TeaserCarousel.TinySlider .tns-inner {
    margin-left: unset !important;
  }
}
@media only screen and (min-width: 57.5em), print {
  .TeaserCarousel.TinySlider .tns-ovh {
    margin-right: -30px;
  }
}
.TeaserCarousel.TinySlider .TinySlider-header {
  margin-right: 0;
  height: auto;
  padding-bottom: 1.5625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.TeaserCarousel.TinySlider .TinySlider-header .tns-controls {
  position: relative;
}
.TeaserSlider {
  background: #e9e4e3;
}
.TeaserSlider-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
@media only screen and (min-width: 57.5em), print {
  .TeaserSlider-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.TeaserSlider-content {
  text-align: center;
}
@media only screen and (min-width: 57.5em), print {
  .TeaserSlider-content {
    padding-right: 100px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 50%;
    text-align: left;
  }
}
.TeaserSlider-imageWrapper {
  position: relative;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}
@media only screen and (min-width: 57.5em), print {
  .TeaserSlider-imageWrapper {
    margin: 0;
  }
}
.TeaserSlider-imageWrapper .TeaserSlider-image {
  width: 100%;
  min-height: 100%;
  margin-bottom: 1.25rem;
}
@media only screen and (min-width: 57.5em), print {
  .TeaserSlider-imageWrapper .TeaserSlider-image {
    margin: 0;
    max-width: 524px;
    max-height: 350px;
  }
}
.TeaserSlider-imageWrapper .TeaserSlider-shape {
  display: none;
}
@media only screen and (min-width: 57.5em), print {
  .TeaserSlider-imageWrapper .TeaserSlider-shape {
    display: block;
    position: absolute;
    bottom: -70px;
    width: auto;
  }
}
.TeaserSlider-imageWrapper .TeaserSlider-imageFade {
  display: none;
}
@media only screen and (min-width: 57.5em), print {
  .TeaserSlider-imageWrapper .TeaserSlider-imageFade {
    height: 100%;
    width: 30%;
    right: -30px;
    background: -webkit-gradient(linear, left top, right top, color-stop(10%, rgba(255, 255, 255, 0)), color-stop(80%, #e9e4e3));
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 10%, #e9e4e3 80%);
    top: 0;
    position: absolute;
    display: block;
  }
}
.TeaserSlider-nav .tns-inner {
  margin-right: -2px !important;
}
.TeaserSlider-nav .TinySlider-slider {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
}
.TeaserSlider-nav .TinySlider-slider .TeaserSlider-navItem {
  padding: 0;
  width: 22px !important;
  position: relative;
  display: inline-block;
}
.TeaserSlider-nav .TinySlider-slider .TeaserSlider-navItem .tns-nav {
  display: inline-block;
  margin-right: 10px;
}
.TeaserSlider-nav .TinySlider-slider .TeaserSlider-navItem .tns-nav button {
  display: inline-block;
}
.TeaserSlider-nav .TinySlider-slider .TeaserSlider-navItem .TeaserSlider-label,
.TeaserSlider-nav .TinySlider-slider .TeaserSlider-navItem .TeaserSlider-name {
  display: none;
}
@media only screen and (min-width: 48em), print {
  .TeaserSlider-nav .TinySlider-slider .TeaserSlider-navItem {
    border-top: 3px solid #fff;
    padding: 20px 30px 10px 30px;
    display: block;
    width: 100%;
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
  }
  .TeaserSlider-nav .TinySlider-slider .TeaserSlider-navItem:after {
    content: '';
    width: 1px;
    height: 50%;
    background: #ede9e9;
    position: absolute;
    right: 0;
    top: 30px;
  }
  .TeaserSlider-nav .TinySlider-slider .TeaserSlider-navItem .tns-nav {
    display: none !important;
  }
  .TeaserSlider-nav .TinySlider-slider .TeaserSlider-navItem .TeaserSlider-label {
    display: inline-block;
  }
  .TeaserSlider-nav .TinySlider-slider .TeaserSlider-navItem .TeaserSlider-name {
    display: block;
  }
}
.TeaserSlider-nav .TinySlider-slider .tns-nav-active.TeaserSlider-navItem {
  border-color: #18274a;
}
.TeaserSlider-nav .TinySlider-slider .tns-nav-active.TeaserSlider-navItem .TeaserSlider-name {
  font-weight: bold;
}
.TeaserSlider-nav .TinySlider-slider .tns-nav-active.TeaserSlider-navItem .tns-nav button {
  background: #18274a;
}
.TeaserSlider-nav .TinySlider-slider .TeaserSlider-label {
  color: #18274a;
  margin-bottom: 10px;
  font-size: 14px;
}
@media only screen and (min-width: 57.5em), print {
  .TinySlider .tns-outer {
    margin-left: 0.5rem;
  }
}
.TinySlider .tns-inner {
  margin-left: 0 !important;
}
.TinySlider .tns-ovh {
  margin-left: -15px;
  margin-right: -15px;
}
@media only screen and (min-width: 57.5em), print {
  .TinySlider .tns-ovh {
    margin-left: 0;
  }
}
.TinySlider .TinySlider-header {
  position: relative;
  margin-right: -15px;
  height: 55px;
}
.TinySlider .TinySlider-header .TinySlider-heading {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.TinySlider .TinySlider-header .TinySlider-heading.Heading--textCenter {
  text-align: center;
}
.TinySlider .TinySlider-header .tns-controls {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}
.TinySlider .TinySlider-header .tns-controls button {
  width: 40px;
  height: 40px;
  background: #fff;
  border: 2px solid #ede9e9;
  border-radius: 100%;
  color: #18274a;
}
.TinySlider .TinySlider-header .tns-controls button:disabled {
  opacity: 0.4;
}
.TinySlider .TinySlider-header .tns-controls button.tns-prevButton {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.TinySlider .TinySlider-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.TinySlider-slider .tns-item[aria-hidden=true] a {
  visibility: hidden;
}
.Asset-video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Asset-video .Asset-video--wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.Asset-video .Asset-video--wrapper iframe {
  width: 100%;
  aspect-ratio: 16/9;
}
.Asset-video .Asset-video--wrapper video {
  width: 100%;
}
.Asset-video .Asset-video--wrapper img.Asset-video--poster {
  position: absolute;
}
.Asset-video .Asset-video--wrapper .playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.25);
          transform: translate(-50%, -50%) scale(1.25);
  opacity: 0.8;
  fill: #e9e4e3;
}
.Asset-video .Asset-video--wrapper .playButton:hover {
  fill: #18274a;
  cursor: pointer;
}
.VideoBlock--themeSnow .Asset-video--wrapper .playButton,
.Asset-video--wrapper.Asset--themeSnow .playButton {
  fill: #18274a;
}
.VideoBlock--themeSnow .Asset-video--wrapper .playButton:hover,
.Asset-video--wrapper.Asset--themeSnow .playButton:hover {
  fill: #e9e4e3;
}
.Banner:has(.Asset-video) .Banner-content {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  max-width: 100%;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}
.Banner:has(.Asset-video) .Banner-content .Banner-videoClip {
  width: auto;
  left: auto;
  position: relative;
  margin: -0.5em 0;
}
.Banner:has(.Asset-video) .Banner-content .Banner-textWrapper {
  width: 100% !important;
  left: auto !important;
  position: absolute;
  top: 0;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
.Banner:has(.Asset-video).Banner--imageRight .Banner-textWrapper {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.Banner:has(.Asset-video).Banner--imageRight .Banner-textWrapper,
.Banner:has(.Asset-video).Banner--imageLeft .Banner-textWrapper {
  position: relative;
}
.Banner:has(.Asset-video).Banner--bgCoverContent .Banner-content {
  padding: 0;
}
.Banner:has(.Asset-video).Banner--bgCoverContent.Banner--imageRight .Banner-videoClip,
.Banner:has(.Asset-video).Banner--bgCoverContent.Banner--imageLeft .Banner-videoClip {
  padding-bottom: 1.5625rem;
  padding-top: 1.5625rem;
}
@media only screen and (min-width: 57.5em), print {
  .Banner:has(.Asset-video).Banner--bgCoverContent.Banner--imageRight .Banner-videoClip,
  .Banner:has(.Asset-video).Banner--bgCoverContent.Banner--imageLeft .Banner-videoClip {
    padding-bottom: 3.75rem;
    padding-top: 3.75rem;
  }
}
.Banner:has(.Asset-video):not(.Banner--bgCoverContent) {
  padding: 0;
}
.Column .Column-video:has(.Asset-video) {
  margin-bottom: -0.5em;
}
.Column-video.Wistia-video {
  max-width: 100%;
  aspect-ratio: unset;
}
.Column-video.Wistia-video .wistia_responsive_wrapper {
  max-height: 100%;
}
@media only screen and (min-width: 48em), print {
  .Column-video.Wistia-video {
    max-width: unset;
    aspect-ratio: 16/9;
  }
  .Column-video.Wistia-video .wistia_responsive_wrapper {
    max-height: unset;
  }
}
.imageMapper--pinDetails.wistia_embed {
  position: unset;
}
.imageMapper--pinDetails.wistia_embed .wistia_click_to_play {
  background-color: unset;
}
/*#

Component for containing the entire page. Useful for setting page wide styling.

*/
.Page-wrapper {
  margin-left: auto;
  margin-right: auto;
}
.Page-container,
.Article-header,
.DefinitionList,
.DownloadList-item,
.Section-header,
.SplitContent {
  max-width: 1220px;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: auto;
  margin-right: auto;
}
.CMS-container {
  background: hsl(0, 0%, 93%);
  max-width: 1220px;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-left: auto !important;
  margin-right: auto !important;
}
.CMS-container .CMS-propertyEditor-Container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.CMS-container .CMS-propertyEditor-Container .CMS-propertyEditor {
  border: 1px solid #999;
  -webkit-box-shadow: 1px 1px #999;
          box-shadow: 1px 1px #999;
  width: 100% !important;
  background-color: #ccc;
}
